@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.MainPage_testphoto31Icon__16GMk {
  position: absolute;
  top: 4473px;
  left: 675px;
  border-radius: 48px;
  width: 434px;
  height: 334px;
  object-fit: cover;
  display: none;
}
.MainPage_hover__GTnVz {
  /* display: flex; */
  position: relative;
}
.MainPage_overlay1__10SaR,
.MainPage_overlay2__25Mn7,
.MainPage_overlay3__2P9RU,
.MainPage_overlay4__1EHyE,
.MainPage_overlay5__159ui,
.MainPage_overlay6__1U4EI,
.MainPage_overlay7__w1vhV,
.MainPage_overlay8__37EeK {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: var(--br-11xl);
  opacity: 0;
}
.MainPage_scopegradientorb2Icon__1DMPN {
  position: absolute;
  /* width: 81.14%; */
  visibility: 'inherit';
  overflow-x: hidden;
  top: 2402px;
  right: 0;
  left: 30%;
  max-width: 100%;
  overflow: visible;
  height: 2611.83px;
  object-fit: cover;
}
.MainPage_testphoto21Icon__3hm4q {
  position: absolute;
  top: 4408px;
  left: 1072px;
  width: 447px;
  height: 427px;
  object-fit: cover;
  display: none;
}
.MainPage_globewShadow1Icon__2Ad9b,
.MainPage_scopegradientorb1Icon__cKKPK {
  position: absolute;
  width: 79.57%;
  top: 4817px;
  right: 20.43%;
  left: 0;
  max-width: 100%;
  overflow: visible;
  border: 0;
  height: 2447.04px;
  object-fit: cover;
}
.MainPage_scopegradientorb1Icon__cKKPK {
  /* width: %; */
  top: 0;
  left: 0%;
  /* transition: all .3s ease-in-out; */
  right: 39.56%;
  height: 1273.47px;
  border: 0;
}
.MainPage_globewShadow1Icon__2Ad9b {
    position: absolute;
  width: 79.57%;
  top: 4500px;
  right: 20.43%;
  left: 0;
  max-width: 100%;
  overflow: visible;
  height: 2447.04px;
  object-fit: cover;
}
.MainPage_applicationsForThe__1kc6u {
  margin: 0;
  font-size: 2.2rem;
  font-weight: bold;
  width: 200%;
}
.MainPage_applicationsForTheSwift__10MHk {
  margin: 0;

}
.MainPage_applicationsForTheContainer__1Yp5p {
  position: absolute;
  top: 7.34%;
  left: 11.09%;
  font-size: var(--font-size-5xl);
  font-weight: 300;
  color: var(--color-white);
  text-align: left;
  display: inline-block;
  width: 685px;
  height: 135px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  /* -webkit-text-stroke: 1px #000; */
}
.MainPage_rectangleIcon3__VI1hw {
  position: absolute;
  height: 79.89%;
  width: 43.11%;
  top: 0.34%;
  right: 38.6%;
  bottom: 19.77%;
  left: 18.29%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.MainPage_rectangleIcon__3Gd97,
.MainPage_rectangleIcon1__38pIM {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.MainPage_rectangleIcon__3Gd97 {
  position: absolute;
  height: 75.83%;
  width: 42.06%;
  top: 0.34%;
  right: 39.13%;
  bottom: 23.83%;
  left: 18.81%;
  overflow: hidden;
}
.MainPage_rectangleIcon1__38pIM {
  height: 73.22%;
  width: 16.07%;
  top: 0;
  right: 83.93%;
  bottom: 26.78%;
  left: 0;
}
.MainPage_mainlogo__TibY1,
.MainPage_rectangleIcon1__38pIM,
.MainPage_rectangleIcon2__2ykOU {
  position: absolute;
  overflow: hidden;
}
.MainPage_rectangleIcon2__2ykOU {
  height: 98.14%;
  width: 36.14%;
  top: 2.17%;
  right: -0.08%;
  bottom: -0.31%;
  left: 63.95%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.MainPage_mainlogo__TibY1 {
  top: 319px;
  left: 200px;
  width: 821px;
  height: 212px;
  display: none;
  background-image: url('/whiteScope.png');
}
.MainPage_mainlogo__TibY1:hover {
  background-image: url('/purpleScope.png');
}
.MainPage_top__3qrZr {
  position: absolute;
  top: 514px;
  left: 11%;
  font-size: var(--font-size-21xl);
  font-weight: 600;
  color: var(--color-white);
  text-align: left;
  display: inline-block;
  width: 579px;
  height: 74px;
}
.MainPage_learnBuildCode__hg2Wg {
  position: absolute;
  top: 6.4%;
  left: 11%;
  font-size: var(--font-size-21xl);
  font-weight: 600;
  color: var(--color-white);
  text-align: left;
  display: inline-block;
  width: 620px;
  height: 74px;
}
.MainPage_top__3qrZr {
  top: 19px;
  left: 212px;
  color: var(--color-linen);
}
.MainPage_applyNow__3wq4W {
  position: absolute;
  top: 9.3%;
  font-size: 30px;
  padding: 2rem 20rem;
  font-family: 'Raleway';
  border-radius: 25px;
  background: #f4f1de;
  /* background: linear-gradient(180deg, rgba(164,131,236,1) 0%, rgba(103,60,205,1) 100%);  z-index: 99; */
  color: #5b04f6;
  left: 11%;
  font-size: 2.3rem;
  z-index: 1;
  font-weight: bold;
  box-shadow: rgb(5, 18, 64) 5px 5px;
  transition: box-shadow .2s linear, translate .2s linear;
}

.MainPage_applyNow__3wq4W::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(30deg, rgba(164,131,236,1) 0%, rgba(103,60,205,1) 100%);  z-index: 99;
  z-index: -1;
  opacity: 0;
  transition: opacity .3s linear;
  border-radius: 25px;
}

.MainPage_applyNow__3wq4W:hover::before {
  opacity: 1;
}

.MainPage_applyNow__3wq4W:hover {
  background-color: #5b04f6;
  color: #F4F2EB;
  cursor: pointer;
  /* background-color: white; */
  z-index: 99;
  left: 11%;
  box-shadow: rgb(5, 18, 64) 10px 10px;
  translate: -5px -5px; 
}

.MainPage_applyNow__3wq4W:active {
  box-shadow: rgb(5, 18, 64) 0px 0px;
  translate: 0px 0px; 
}

.MainPage_hackathonPhotoIcon__2FYyo,
.MainPage_paulphotoIcon__2cHG1 {
  position: absolute;
  top: 1821px;
  left: -7%;
  width: 831px;
  height: 690px;
  object-fit: cover;
}
.MainPage_paulphotoIcon__2cHG1 {
  top: 1081px;
  left: -15%;
  width: 660.97px;
  height: 1136.97px;
}
.MainPage_eachSemesterWe__33axJ {
  position: absolute;
  top: 4368px;
  left: calc(50% - 414.5px);
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  display: inline-block;
  width: 827px;
  height: 503px;
}
.MainPage_buildAndLearn__1lmOT,
.MainPage_thatsTheMottoContainer__3skep {
  position: relative;
  display: inline-block;
  width: 904px;
  height: 85px;
  flex-shrink: 0;
}
.MainPage_thatsTheMottoContainer__3skep {
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  width: 900px;
  height: 503px;
}
.MainPage_mottoFrame__2YlZY {
  position: absolute;
  top: 1467px;
  left: calc(50% - 451.5px);
  width: 905px;
  height: 608px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  grid-gap: 20px;
  gap: 20px;
  font-size: var(--font-size-21xl);
}
.MainPage_about__Rr-Za,
.MainPage_ourCurriculum__2GM5n {
  display: inline-block;
  height: 146px;
}
.MainPage_about__Rr-Za {
  position: absolute;
  top: 1237px;
  left: 472px;
  font-size: var(--font-size-21xl);
  font-family: 'Raleway';
  color: var(--color-linen);
  width: 826px;
}
.MainPage_ourCurriculum__2GM5n {
  top: 2953px;
  left: calc(50% - 473.5px);
}
.MainPage_learn__2xOGU,
.MainPage_ourCurriculum__2GM5n,
.MainPage_theCohort__2G-Uj {
  position: absolute;
  width: 949px;
}
.MainPage_learn__2xOGU {
  top: 2787px;
  left: 389px;
  display: inline-block;
  font-family: 'Raleway';
  color: var(--color-linen);
  height: 146px;
}
.MainPage_theCohort__2G-Uj {
  top: 4284px;
  left: calc(50% - 475.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
}
.MainPage_theCountdown__2ub2g {
  top: 11%;
  left: 40px;
  /* display: flex; */
  font-weight: 800;
  color: var(--color-linen);
  font-family: 'Raleway';
  align-items: center;
  /* justify-content: center; */
  height: 74px;
}
.MainPage_cohort__1GX9Y,
.MainPage_frequentlyAskedQuestions__1xer4 {
  position: absolute;
  display: inline-block;
  width: 949px;
  height: 146px;
}
.MainPage_cohort__1GX9Y {
  top: 4250px;
  display: inline-block;
  left: 300px;
  font-family: 'Raleway';
  color: var(--color-linen);
}
.MainPage_frequentlyAskedQuestions__1xer4 {
  top: 5175px;
  left: calc(50% - 473.5px);
}
.MainPage_theBoard__1Rtbj {
  top: 6723px;
  left: calc(50% - 469.5px);
  width: 949px;
  height: 146px;
}
.MainPage_atScopeWe__1eWct,
.MainPage_faq__2BzNj,
.MainPage_theBoard__1Rtbj {
  position: absolute;
  display: inline-block;
}
.MainPage_faq__2BzNj {
  top: 4999px;
  left: 405px;
  font-family: 'Raleway';
  color: var(--color-linen);
  width: 949px;
  height: 146px;
}
.MainPage_atScopeWe__1eWct {
  top: 3047px;
  left: calc(50% - 497.5px);
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  width: 997px;
  height: 337px;
}
.MainPage_jakepointingIcon__1qg9c {
  position: absolute;
  top: 1443px;
  right: -13%;
  /* width: 383px; */
  height: 1038.45px;
  object-fit: cover;
}
.MainPage_facebookButtonIcon__2ZAHo,
.MainPage_githubButtonIcon__35U6_,
.MainPage_instagramButtonIcon__1eLg_ {
  position: absolute;
  top: 456px;
  right: 71px;
  width: 60px;
  height: 58px;
  object-fit: cover;
  cursor: pointer;
}
.MainPage_facebookButtonIcon__2ZAHo,
.MainPage_instagramButtonIcon__1eLg_ {
  top: 458px;
  right: 147px;
  width: 54px;
  height: 54px;
}
.MainPage_instagramButtonIcon__1eLg_ {
  right: 1px;
  height: 56px;
}
.MainPage_about1__2b888,
.MainPage_cohort1__1MYWW,
.MainPage_faq1__1tzxu,
.MainPage_learn1__uXGui,
.MainPage_scopeCup__2Z292,
.MainPage_sponsor__2X6zF {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 201px;
  height: 65px;
  cursor: pointer;
}
.MainPage_cohort1__1MYWW,
.MainPage_faq1__1tzxu,
.MainPage_learn1__uXGui,
.MainPage_scopeCup__2Z292,
.MainPage_sponsor__2X6zF {
  top: 380px;
}
.MainPage_cohort1__1MYWW,
.MainPage_faq1__1tzxu,
.MainPage_learn1__uXGui,
.MainPage_sponsor__2X6zF {
  top: 304px;
}
.MainPage_cohort1__1MYWW,
.MainPage_faq1__1tzxu,
.MainPage_learn1__uXGui {
  top: 228px;
}
.MainPage_cohort1__1MYWW,
.MainPage_learn1__uXGui {
  top: 152px;
}
.MainPage_learn1__uXGui {
  top: 76px;
}
.MainPage_about2__18Kl7,
.MainPage_cohort2__9HhXA,
.MainPage_faq2__3kQ0i,
.MainPage_learn2__1ePfy,
.MainPage_linkStack__2tdPv,
.MainPage_scopeCup1__2D1mw,
.MainPage_sponsor1__1Gop5 {
  position: absolute;
  font-size: var(--font-size-13xl);
  text-align: right;
}
.MainPage_linkStack__2tdPv {
  top: 355px;
  right: 159px;
  width: 223px;
  height: 514px;
}
.MainPage_about2__18Kl7,
.MainPage_cohort2__9HhXA,
.MainPage_faq2__3kQ0i,
.MainPage_learn2__1ePfy,
.MainPage_scopeCup1__2D1mw,
.MainPage_sponsor1__1Gop5 {
  left: 1377px;
  display: none;
  width: 201px;
  height: 65px;
}
.MainPage_about2__18Kl7 {
  top: 324px;
  cursor: pointer;
}
.MainPage_cohort2__9HhXA,
.MainPage_faq2__3kQ0i,
.MainPage_learn2__1ePfy,
.MainPage_scopeCup1__2D1mw,
.MainPage_sponsor1__1Gop5 {
  top: 704px;
}
.MainPage_cohort2__9HhXA,
.MainPage_faq2__3kQ0i,
.MainPage_learn2__1ePfy,
.MainPage_sponsor1__1Gop5 {
  top: 628px;
}
.MainPage_cohort2__9HhXA,
.MainPage_faq2__3kQ0i,
.MainPage_learn2__1ePfy {
  top: 552px;
  cursor: pointer;
}
.MainPage_cohort2__9HhXA,
.MainPage_learn2__1ePfy {
  top: 476px;
}
.MainPage_learn2__1ePfy {
  top: 400px;
}

.MainPage_scopeOffWhite3d2__KAWZn {
  position: absolute;
  top: 0;
  right: 0;

  /* border: 1px solid var(--color-black); */
  box-sizing: border-box;
  width: 761px;
  height: 197px;
  overflow: hidden;
}

.MainPage_scopeLogo__1OVth {
  -webkit-animation-name: MainPage_floating__3nhoN;
          animation-name: MainPage_floating__3nhoN;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  position: absolute;
  top: 3.5%;
  left: 10%;
  width: 780px;
  height: 230px;
  visibility: 'inherit';
  background-image: url('/whiteScope.png');
  background-repeat: no-repeat;
  background-size: 780px;
  transition: all .6s ease-in-out;
  /* transition: height .7s ease-in; */
}
.MainPage_scopeLogo__1OVth:hover {
  width: 795px;
  cursor: pointer;
  -webkit-filter: drop-shadow(-5px -5px 10px #E1E1E1);
          filter: drop-shadow(-5px -5px 10px #E1E1E1);
  background-size: 795px;
  background-image: url('/purpleScope.png');
  transition: all .6s ease-in-out;
}
@-webkit-keyframes MainPage_floating__3nhoN {
  0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
  50%  { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
  100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
@keyframes MainPage_floating__3nhoN {
  0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
  50%  { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
  100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
.MainPage_belongSymbolIcon__23E46,
.MainPage_bondSymbolIcon__1xZCV,
.MainPage_learnSymbolIcon__3Qq8y {
  position: absolute;
  top: 8px;
  left: calc(50% - 451.5px);
  width: 128px;
  height: 105px;
  object-fit: cover;
}
.MainPage_belongSymbolIcon__23E46,
.MainPage_bondSymbolIcon__1xZCV {
  top: 0;
  left: calc(50% - 45.5px);
  width: 98px;
  height: 130px;
}
.MainPage_belongSymbolIcon__23E46 {
  left: calc(50% + 318.5px);
  width: 142px;
  height: 144px;
}

.MainPage_name__2wAh8 {
  opacity: 1;
  font-size: 1.8rem;
  font-family: 'Raleway';
  font-weight: 510;
  position: absolute;
  color: #5b04f6;
  text-align: center;
  z-index: 99;
  top: 85%;
  left: 50%;
  /* -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  text-align: center;
}
  .MainPage_role__33P5g {
    font-size: 1rem;
    font-family: 'Raleway';
    text-transform: uppercase;
    font-weight: 700;
    color: black; 
    white-space: nowrap;
  }
  .MainPage_hover__GTnVz:hover {
    cursor: pointer;
    .MainPage_img__1Bgh4 {
      opacity: .8;
    }
    .MainPage_eboardImg1__3I7Qu,
    .MainPage_eboardImg2__3hK7G,
    .MainPage_eboardImg3__KiqJl,
    .MainPage_eboardImg4__2S6xS,
    .MainPage_eboardImg5__3zlGj,
    .MainPage_eboardImg6__1a5ej,
    .MainPage_eboardImg7__ceBUL,
    .MainPage_eboardImg8__2iyqI {
      opacity: .6;
      border-radius: 75px;
    }
    .MainPage_overlay1__10SaR {
      opacity: 1;
      top: 70px;
      left: -44%;
    }
    .MainPage_overlay2__25Mn7 {
      opacity: 1;
      top: 70px;
      left: -17.5%;
    }
    .MainPage_overlay3__2P9RU {
      opacity: 1;
      top: 70px;
      left: 6%;
    }
    .MainPage_overlay4__1EHyE {
      opacity: 1;
      top: 70px;
      left: 34%;
    }
    .MainPage_overlay5__159ui {
      opacity: 1;
      top: 280px;
      left: -45%;
    }
    .MainPage_overlay6__1U4EI {
      opacity: 1;
      top: 280px;
      left: -18.5%;
    }
    .MainPage_overlay7__w1vhV {
      opacity: 1;
      top: 280px;
      left: 8.5%;
    }
    .MainPage_overlay8__37EeK {
      opacity: 1;
      top: 280px;
      left: 33.5%;
    }
  
  }
  .MainPage_img__1Bgh4 {
    display: flex;
    flex-direction: column;
    max-width: 100px;
    max-height:100px;
    align-items: center;
    color: white;
    text-align: center;
  }
  .MainPage_eboardImg8__2iyqI {
    position: absolute;
    top: 216px;
    left: 78%;
    width: 193px;
    height: 192px;
    /* object-fit: cover; */
  }
  .MainPage_eboardImg7__ceBUL {
    position: absolute;
    top: 216px;
    left: 52.3%;
    width: 193px;
    height: 192px;
    /* object-fit: cover; */
  }
  .MainPage_eboardImg6__1a5ej {
    position: absolute;
    top: 216px;
    left: 26.2%;
    width: 193px;
    height: 192px;
    /* object-fit: cover; */
  }
  .MainPage_eboardImg5__3zlGj {
    position: absolute;
    top: 216px;
    width: 193px;
    height: 192px;
    /* object-fit: cover; */
  }
  .MainPage_eboardImg4__2S6xS {
    position: absolute;
    top: 0%;
    left: 78%;
    width: 193px;
    height: 192px;
    /* object-fit: cover; */
  }
  .MainPage_eboardImg3__KiqJl {
    position: absolute;
    top: 0%;
    left: 52.3%;
    width: 193px;
    height: 192px;
    /* object-fit: cover; */
  }
  .MainPage_eboardImg2__3hK7G {
    position: absolute;
    top: 0%;
    left: 26.2%;
    width: 193px;
    height: 192px;
    /* object-fit: cover; */
  }
  .MainPage_eboardImg1__3I7Qu {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 193px;
    height: 192px;
    border-radius: 75px;
    /* object-fit: cover; */
  }
.MainPage_belong__HazdV,
.MainPage_collaborate__33Vzc,
.MainPage_learn3__1kmcR,
.MainPage_learnACuttingEdge__2uTDa {
  position: absolute;
  top: 202px;
  left: calc(50% - 556.5px);
  display: inline-block;
  width: 331px;
  height: 447px;
}
.MainPage_belong__HazdV,
.MainPage_collaborate__33Vzc,
.MainPage_learn3__1kmcR {
  top: 149px;
  left: calc(50% - 553.5px);
  font-size: var(--font-size-13xl);
  font-weight: 800;
  color: var(--color-blueviolet);
  height: 35px;
}
.MainPage_belong__HazdV,
.MainPage_collaborate__33Vzc {
  top: 153px;
  left: calc(50% - 158.5px);
}
.MainPage_belong__HazdV {
  left: calc(50% + 225.5px);
  height: 31px;
}
.MainPage_joinATightKnit___qWJi,
.MainPage_teamUpIdeate__PbH8s {
  position: absolute;
  top: 202px;
  left: calc(50% - 165.5px);
  display: inline-block;
  width: 337px;
  height: 447px;
}
.MainPage_joinATightKnit___qWJi {
  left: calc(50% + 219.5px);
}
.MainPage_pillarsFrame__3nP5z {
  position: absolute;
  top: 2498px;
  left: calc(50% - 555.5px);
  width: 1113px;
  height: 334px;
  font-size: var(--font-size-xl);
  color: var(--color-black);
}
.MainPage_arrow1Icon__3-OKX {
  position: absolute;
  top: 1919px;
  left: 609px;
  width: 308.84px;
  height: 331.06px;
  object-fit: cover;
  opacity: 0.8;
}
.MainPage_reactNativeSpringContainer__2PkED,
.MainPage_theAlumni__2Ra76 {
  position: absolute;
  top: 7614px;
  left: calc(50% - 486.5px);
  display: inline-block;
  width: 949px;
  height: 146px;
  z-index: 99;
}
.MainPage_reactNativeSpringContainer__2PkED {
  height: 73.49%;
  top: 13.27%;
  left: calc(50% - 47.5px);
  width: 225px;
}
.MainPage_nextlogoIcon__27orV {
  position: absolute;
  top: calc(50% - 43px);
  left: 7px;
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.MainPage_reactlogo1Icon__bUSEo {
  position: absolute;
  top: calc(50% - 43px);
  left: -9px;
  width: 98px;
  height: 98px;
  object-fit: contain;
}
.MainPage_reactNativeSpring2022__3onrb {
  position: relative;
  width: 335px;
  height: 98px;
}
.MainPage_reactJsContainer__2xtqW {
  position: absolute;
  height: 74.39%;
  top: 13.06%;
  left: calc(50% - 139px);
  display: inline-block;
  width: 408px;
}
.MainPage_reactlogo1Icon1__1hF88 {
  position: absolute;
  top: calc(50% - 43.2px);
  left: -9px;
  width: 98px;
  height: 87px;
  object-fit: contain;
}
.MainPage_reactJsAndTypescript__1eNT4 {
  position: relative;
  width: 518px;
  height: 98px;
}
.MainPage_fall2020Virtual__3mBIt {
  margin: 0;
  font-size: var(--font-size-5xl);
}
.MainPage_extensionsFirebaseContainer___MNUG {
  position: absolute;
  height: 65.22%;
  top: 16.94%;
  left: calc(50% - 133.5px);
  display: inline-block;
  width: 397px;
}
.MainPage_chromelogo1Icon__Gl5BA {
  position: absolute;
  height: 100%;
  top: -0.41%;
  bottom: 0.41%;
  left: calc(50% - 262.5px);
  max-height: 100%;
  width: 98px;
  object-fit: cover;
}
.MainPage_extensionsAndFirebase__1xWI4 {
  position: relative;
  width: 507px;
  height: 98px;
}
.MainPage_reactJsFallContainer__3A4Sm {
  position: absolute;
  height: 71.08%;
  top: 15.71%;
  left: calc(50% - 47.5px);
  display: inline-block;
  width: 163px;
}
.MainPage_reactlogo1Icon2__1YGiQ {
  position: absolute;
  top: calc(50% - 42.6px);
  left: -9px;
  width: 98px;
  height: 87px;
  object-fit: contain;
}
.MainPage_electronFall2018Container__2MyiZ {
  position: absolute;
  height: 64.52%;
  top: 22.65%;
  left: calc(50% - 46px);
  display: inline-block;
  width: 225px;
}
.MainPage_electronlogo1Icon__1E38S {
  position: absolute;
  height: 97px;
  top: 0.2%;
  bottom: -9.39%;
  left: calc(50% - 175px);
  /* max-height: 100%; */
  width: 99px;
  object-fit: contain;
}
.MainPage_electron__j6kRz {
  position: relative;
  width: 332px;
  height: 98px;
}
.MainPage_reactNativeFallContainer__3Wi6V {
  position: absolute;
  height: 73.17%;
  top: 14.29%;
  left: calc(50% - 47.5px);
  display: inline-block;
  width: 225px;
}
.MainPage_leftCurriculumColumn__3aegI {
  width: 518px;
  height: 859px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.MainPage_tbdFall2023Container__3SsHj {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 48px);
  display: inline-block;
  width: 224px;
  height: 61px;
}
.MainPage_reactJsSpring2023Container__1XbsH {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 48px);
  display: inline-block;
  width: 224px;
  height: 61px;
}
.MainPage_reactNativeSpring2022Container__2f24t {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 48px);
  display: inline-block;
  width: 224px;
  height: 61px;
}
.MainPage_shadowwhitequestionmark1Icon__SFpeX {
  position: absolute;
  width: 100%;
  top: 10px;
  right: 10px;
  left: -7px;
  max-width: 100%;
  overflow: visible;
  height: 90px;
  object-fit: cover;
}
.MainPage_questionmark__2GgJz {
  position: absolute;
  top: calc(50% - 58px);
  left: calc(50% - 159px);
  width: 85px;
  height: 115px;
}
.MainPage_tbdFall2023__ctGd8 {
  position: relative;
  width: 336px;
  height: 98px;
}
.MainPage_reactJsAndFirebase__2-Q0g {
  position: relative;
  width: 336px;
  height: 98px;
}
.MainPage_nodejslogo1Icon__YIXIn {
  position: relative;
  width: 83px;
  height: 98px;
  object-fit: cover;
}
.MainPage_nodeJsFallContainer__xfiSj {
  position: relative;
  display: inline-block;
  width: 225px;
  height: 61px;
  flex-shrink: 0;
}
.MainPage_nodeJsFall2022__1hakO {
  width: 340px;
  height: 98px;
  display: flex;
  flex-direction: row;
  padding: 0 0 0.199951171875px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 37px;
  gap: 37px;
}
.MainPage_reactfalllogo1Icon__1AyVo {
  position: relative;
  padding-right: 9px;
  width: 98px;
  height: 98px;
  object-fit: contain;
}
.MainPage_swiftlogo1Icon__29VMG {
  position: relative;
  margin-top: 20px;
  width: 88px;
  height: 88px;
  object-fit: cover;
}
.MainPage_swiftSpring2021Container__1P7tG {
  position: relative;
  display: inline-block;
  width: 303px;
  height: 60px;
  flex-shrink: 0;
}
.MainPage_swiftSpring2021__PdefE {
  width: 423px;
  height: 98px;
  display: flex;
  flex-direction: row;
  padding: 0 0 10.39990234375px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 32px;
  gap: 32px;
}
.MainPage_electronlogo2Icon__12DZP {
  position: relative;
  width: 86px;
  height: 94px;
  object-fit: cover;
}
.MainPage_electronSpring2020Container__3i1dF {
  position: relative;
  display: inline-block;
  width: 224px;
  height: 59px;
  flex-shrink: 0;
}
.MainPage_electronSpring2020__2bk1L {
  width: 332px;
  height: 98px;
  display: flex;
  flex-direction: row;
  padding: 0.39990234375px 0 3.60009765625px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 34px;
  gap: 34px;
}
.MainPage_flutterlogo1Icon__3rRhS {
  position: relative;
  width: 72px;
  height: 87px;
  object-fit: cover;
}
.MainPage_flutterSpring2019Container__1y-yL {
  position: relative;
  display: inline-block;
  width: 224px;
  height: 61px;
  flex-shrink: 0;
}
.MainPage_flutterSpring2019__2L0JH {
  width: 332px;
  height: 98px;
  display: flex;
  flex-direction: row;
  padding: 5.199951171875px 0 5.800048828125px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 48px;
  gap: 48px;
}
.MainPage_nodeJsBackendContainer__JfVht {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 81px);
  display: inline-block;
  width: 419px;
  height: 61px;
}
.MainPage_nodejslogo2Icon__1CpRl {
  position: absolute;
  top: calc(50% - 49px);
  left: calc(50% - 201px);
  width: 83px;
  height: 98px;
  object-fit: cover;
}
.MainPage_nodejsBackendDevopsSpring__8G-a0 {
  position: relative;
  width: 402px;
  height: 98px;
}
.MainPage_curriculum__350JJ,
.MainPage_rightCurriculumColumn__3z0Wo {
  height: 859px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.MainPage_curriculum__350JJ {
  position: absolute;
  top: 3285px;
  left: calc(50% - 518.5px);
  width: 1038px;
  height: 858px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 97px;
  gap: 97px;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
}
.MainPage_menuBarEnter__wlywg {
  position: absolute;
  top: 1340px;
  left: 0;
  background-color: var(--color-linen);
  width: 1727px;
  height: 112px;
}
.MainPage_scopeLooksFor__VDiSD,
.MainPage_weAreAn__3h_NY {
  position: absolute;
  display: inline-block;
}
.MainPage_weAreAn__3h_NY {
  width: 42.17%;
  top: 146px;
  left: 0.21%;
  color: var(--color-linen);
  height: 213px;
}
.MainPage_scopeLooksFor__VDiSD {
  width: 43.57%;
  top: 120px;
  left: 50.63%;
  height: 134px;
}
.MainPage_applicationAtContainer__1SsbT,
.MainPage_around30__1Izlu,
.MainPage_scopeIsTo__3X5yn,
.MainPage_sinceOurCurriculum__2SHj8,
.MainPage_weHaveTwo__3I6R_ {
  position: absolute;
  width: 43.57%;
  top: 782px;
  left: 50.49%;
  display: inline-block;
  height: 193px;
}
.MainPage_applicationAtContainer__1SsbT,
.MainPage_around30__1Izlu,
.MainPage_sinceOurCurriculum__2SHj8,
.MainPage_weHaveTwo__3I6R_ {
  top: 1055px;
}
.MainPage_applicationAtContainer__1SsbT,
.MainPage_around30__1Izlu,
.MainPage_weHaveTwo__3I6R_ {
  top: 345px;
  left: 50.63%;
  height: 380px;
}
.MainPage_around30__1Izlu,
.MainPage_weHaveTwo__3I6R_ {
  width: 49.37%;
  top: 525px;
  left: 0.14%;
  color: var(--color-linen);
  height: 42px;
}
.MainPage_weHaveTwo__3I6R_ {
  width: 42.17%;
  top: 645px;
  height: 144px;
}
.MainPage_mission__1Kgb4 {
  width: 19.72%;
  top: 1px;
  left: 0.14%;
  font-size: var(--font-size-13xl);
  color: var(--color-blueviolet);
}
.MainPage_mission__1Kgb4,
.MainPage_qDoI__3GERw,
.MainPage_qWhatDoes__16C2R,
.MainPage_qWhatDoes1__382Uu,
.MainPage_qWhatMakes__1izkM,
.MainPage_qWhoIs__3VvFo {
  position: absolute;
  display: inline-block;
  height: 60px;
}
.MainPage_qWhatMakes__1izkM {
  width: 46.36%;
  top: 60px;
  left: 0.14%;
  color: var(--color-linen);
}
.MainPage_qDoI__3GERw,
.MainPage_qWhatDoes__16C2R,
.MainPage_qWhatDoes1__382Uu,
.MainPage_qWhoIs__3VvFo {
  width: 49.37%;
  top: 63px;
  left: 50.63%;
}
.MainPage_qDoI__3GERw,
.MainPage_qWhatDoes1__382Uu,
.MainPage_qWhoIs__3VvFo {
  top: 725px;
  left: 50.49%;
}
.MainPage_qDoI__3GERw,
.MainPage_qWhatDoes1__382Uu {
  top: 998px;
}
.MainPage_qWhatDoes1__382Uu {
  top: 288px;
  left: 50.63%;
}
.MainPage_applicationProcess__oNyfE,
.MainPage_involvement__3gygN,
.MainPage_qHowMany__1OuHy,
.MainPage_qWhatIs__hZTKG {
  position: absolute;
  width: 44.76%;
  top: 442px;
  left: 0.14%;
  display: inline-block;
  color: var(--color-linen);
  height: 60px;
}
.MainPage_applicationProcess__oNyfE,
.MainPage_involvement__3gygN,
.MainPage_qWhatIs__hZTKG {
  width: 49.37%;
  top: 589px;
  left: 0;
}
.MainPage_applicationProcess__oNyfE,
.MainPage_involvement__3gygN {
  width: 24.55%;
  top: 0;
  left: 50.63%;
  font-size: var(--font-size-13xl);
  color: var(--color-blueviolet);
}
.MainPage_involvement__3gygN {
  top: 382px;
  left: 0.14%;
}
.MainPage_faq3__3AC7k,
.MainPage_ourNetworkOf__2TOSX {
  position: absolute;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
}
.MainPage_ourCopyright__1xZf9 {
  position: absolute;
  left: 48.6%;
  height: 15px;
  bottom: 0.15%;
  font-size: var(--font-size-5xl);
  color: var(--color-white);
  z-index: 100;
}
.MainPage_ourCopyright2__28PUR {
  position: absolute;
  left: 42.6%;
  bottom: 0.15%;
  font-weight: 300;
  color: black;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  /* color: #5b04f6; */
  z-index: 100;
}
.MainPage_faq3__3AC7k {
  top: 5328px;
  left: calc(50% - 770.5px);
  width: 1541px;
  height: 1248px;
  text-align: left;
}
.MainPage_ourNetworkOf__2TOSX {
  top: 7692px;
  left: calc(50% - 436.5px);
  display: inline-block;
  width: 849px;
  z-index: 99;
  height: 67px;
}
.MainPage_bottomDiv__1k087 {
  overflow: hidden;
  top: 91%;
  max-height: 9%;
  /* position: relative; */
}
.MainPage_bottomGraphicIcon__27zFJ {
  position: absolute;
  width: 80%;
  /* height: 450px; */
  bottom: -10%;
  right: 9.64%;
  left: 9.57%;
  border-radius: 420px;
  max-width: 100%;
  /* overflow- hidden; */
  /* height: 282.48px; */
  object-fit: cover;
}
.MainPage_ourExecutiveBoard__xgrfG {
  position: absolute;
  top: 6816px;
  left: calc(50% - 462.5px);
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  display: inline-block;
  width: 935px;
  height: 503px;
}
.MainPage_amazonIcon__2UyTp,
.MainPage_googleIcon__n4X1n {
  position: absolute;
  top: 0;
  left: calc(50% - 413px);
  width: 214px;
  height: 120px;
  object-fit: cover;
}
.MainPage_amazonIcon__2UyTp {
  top: 36px;
  left: calc(50% - 139px);
  width: 255px;
  height: 77px;
}
.MainPage_bloombergIcon__31lhO,
.MainPage_coinbaseIcon__61xeS,
.MainPage_microsoftIcon__3n9Hu,
.MainPage_snapchatIcon__2YET0 {
  position: absolute;
  top: 134px;
  left: calc(50% - 529px);
  width: 306px;
  height: 65px;
  object-fit: cover;
}
.MainPage_bloombergIcon__31lhO,
.MainPage_coinbaseIcon__61xeS,
.MainPage_snapchatIcon__2YET0 {
  left: calc(50% + 263px);
  width: 266px;
  height: 92px;
}
.MainPage_bloombergIcon__31lhO,
.MainPage_snapchatIcon__2YET0 {
  top: 130px;
  left: calc(50% - 193px);
  width: 97px;
  height: 96px;
}
.MainPage_bloombergIcon__31lhO {
  top: 155px;
  left: calc(50% - 58px);
  width: 296px;
  height: 55px;
}
.MainPage_linkedinLogoIcon__2ySXn,
.MainPage_metaLogoIcon__1rTNp,
.MainPage_stripeLogoIcon__2BVsZ {
  position: absolute;
  top: 12px;
  left: calc(50% + 160px);
  width: 294px;
  height: 95px;
  object-fit: cover;
}
.MainPage_linkedinLogoIcon__2ySXn,
.MainPage_stripeLogoIcon__2BVsZ {
  top: 248px;
  left: calc(50% - 306px);
  width: 292px;
  height: 75px;
}
.MainPage_stripeLogoIcon__2BVsZ {
  top: 230px;
  left: calc(50% + 63px);
  width: 248px;
  height: 118px;
}
.MainPage_alumniCompanies__1mRo- {
  position: absolute;
  top: 7765px;
  left: calc(50% - 528.5px);
  width: 1058px;
  height: 348px;
}
.MainPage_rollercoasterphoto1Icon__2bI9H {
  position: absolute;
  top: 1780px;
  right: -92px;
  width: 707px;
  height: 809px;
  object-fit: cover;
}
.MainPage_ethanSScreenshot1Icon__1fprq,
.MainPage_image2Icon__1kkub,
.MainPage_paulScreenshot1Icon__tiJkY {
  position: absolute;
  top: 1px;
  left: calc(50% - 211px);
  border-radius: var(--br-11xl);
  width: 188px;
  height: 189px;
  object-fit: cover;
}
.MainPage_image2Icon__1kkub,
.MainPage_paulScreenshot1Icon__tiJkY {
  left: calc(50% - 449px);
  height: 188px;
}
.MainPage_image2Icon__1kkub {
  left: calc(50% + 265px);
  width: 189px;
  height: 189px;
}
.MainPage_image3Icon__1ygQY,
.MainPage_image4Icon__2RZWf,
.MainPage_image6Icon__223d3,
.MainPage_jakeScreenshot1Icon__2cGYC,
.MainPage_screenshot20230630At426__3Vhc8 {
  position: absolute;
  top: 216px;
  left: calc(50% - 214px);
  border-radius: var(--br-11xl);
  width: 193px;
  height: 192px;
  object-fit: cover;
}
.MainPage_image4Icon__2RZWf,
.MainPage_image6Icon__223d3,
.MainPage_jakeScreenshot1Icon__2cGYC,
.MainPage_screenshot20230630At426__3Vhc8 {
  left: calc(50% + 264px);
  width: 190px;
  height: 189px;
}
.MainPage_image6Icon__223d3,
.MainPage_jakeScreenshot1Icon__2cGYC,
.MainPage_screenshot20230630At426__3Vhc8 {
  top: 215px;
  left: calc(50% - 454px);
  width: 196px;
  height: 193px;
}
.MainPage_image6Icon__223d3,
.MainPage_screenshot20230630At426__3Vhc8 {
  left: calc(50% + 24px);
  height: 196px;
}
.MainPage_screenshot20230630At426__3Vhc8 {
  top: 0;
  left: calc(50% + 27px);
  width: 188px;
  height: 189px;
}
.MainPage_cohort11__ciH8y,
.MainPage_eboardPhotos__KeumA {
  position: absolute;
  top: 6965px;
  left: calc(50% - 453.5px);
  width: 908px;
  height: 411px;
}
.MainPage_cohort11__ciH8y {
  top: 0;
  left: calc(50% - 232px);
  width: 536px;
  height: 414px;
}
.MainPage_froodphoto1Icon__3tC4U {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.MainPage_cohort21__9be5-,
.MainPage_cohort3__1MSA3,
.MainPage_cohort31__noz-K {
  position: absolute;
  top: 40px;
  left: calc(50% - 677px);
  width: 529px;
  height: 342px;
}
.MainPage_cohort3__1MSA3,
.MainPage_cohort31__noz-K {
  left: calc(50% + 244px);
  width: 433px;
  height: 350px;
}
.MainPage_cohort3__1MSA3 {
  top: 4525px;
  left: calc(50% - 676.5px);
  width: 1354px;
  height: 414px;
}
.MainPage_logo__1g9cv,
.MainPage_purpleinf1Icon__3WVWj {
  position: absolute;
  height: 50px;
}
.MainPage_purpleinf1Icon__3WVWj {
  top: 0;
  left: 0;
  width: 104.62px;
  object-fit: cover;
  cursor: pointer;
}
.MainPage_logo__1g9cv {
  top: 26px;
  left: 64px;
  width: 100px;
}
.MainPage_menuLogo__1m3OQ {
  /* position: relative; */
  /* top: 26px;
  left: 64px;
  width: 96.65px; */
  height: 49px;
  background-image: url(/menu-bar@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  transition: all .3s ease-in-out;
}
.MainPage_menuLogo__1m3OQ:hover {
  height: 47px;
  width: 100px;
  background-image: url(/silverInfinity.png);
  -webkit-filter: drop-shadow(2px 4px 1px var(--color-blueviolet));
          filter: drop-shadow(2px 4px 1px var(--color-blueviolet));
  transition: all .3s ease-in-out;
  cursor: pointer;
}
.MainPage_about3__1aYi-,
.MainPage_cohort4__1dO50,
.MainPage_faq4__2uIFQ,
.MainPage_learn4__1X50s,
.MainPage_scopeCup2__3yZ05,
.MainPage_sponsor2__2YhrH {
  position: absolute;
  float: left;
  width: 7%;
  top: 37px;
  left: 15.26%;
  display: inline-block;
  cursor: pointer;
}
.MainPage_cohort4__1dO50,
.MainPage_faq4__2uIFQ,
.MainPage_learn4__1X50s,
.MainPage_scopeCup2__3yZ05,
.MainPage_sponsor2__2YhrH {
  width: 6.5%;
  left: 27.74%;
}
.MainPage_cohort4__1dO50,
.MainPage_faq4__2uIFQ,
.MainPage_scopeCup2__3yZ05,
.MainPage_sponsor2__2YhrH {
  width: 8.57%;
  left: 39.72%;
}
.MainPage_faq4__2uIFQ,
.MainPage_scopeCup2__3yZ05,
.MainPage_sponsor2__2YhrH {
  width: 3.71%;
  left: 53.77%;
}
.MainPage_scopeCup2__3yZ05,
.MainPage_sponsor2__2YhrH {
  width: 9.69%;
  left: 62.96%;
}
.MainPage_scopeCup2__3yZ05 {
  width: 11.51%;
  left: 78.13%;
}
.MainPage_container__1fNG- {
  width: 900px;
  height: 500px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: space-evenly;

  img {
    border-radius: 12px;
  }
}
.MainPage_scopeLogo__1OVth {
  /* visibility: hidden; */
}
.MainPage_content__3Juk6 {
  /* visibility: hidden; */
  background-color: #f4f2eb;
}
.MainPage_middle__35W-2 {
  color: #5b04f6;
  font-weight: 400;
  font-size: 1.3rem;
  letter-spacing: 2px;
  z-index: 99;
	top: 7%;
	left: 50%;
	position: absolute;
	-webkit-transform: translateX(-50%) translateY(-50%) scale(2, 2);
	        transform: translateX(-50%) translateY(-50%) scale(2, 2);
	transition: 0.5s ease-in-out;
}
/* .mainPage, */
.MainPage_menuBar__2e_8S {
  background-color: var(--color-linen);
  overflow: hidden;
  visibility: hidden;
  position: fixed;
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  color: var(--color-blueviolet);
  font-family: 'Raleway';
}
.MainPage_menuBar__2e_8S {
  margin-bottom: 100px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 102px;
  font-size: var(--font-size-13xl);
}
.MainPage_mainPage__1lJnC {
  position: relative;
  height: 8265px;
  overflow: visible;
  overflow-x: hidden;
  font-size: var(--font-size-31xl);
  background-color: var(--color-linen);
  /* border: 1px solid var(--color-black); */
  /* box-sizing: border-box; */
  width: 100%;
  max-width: 100%;
  text-align: center;
  color: var(--color-blueviolet);
  font-family: 'Raleway';
  background-color: #f4f2eb;
  zoom: 0.67;
}
html{
  scroll-behavior: smooth;
  background-color: #f4f2eb;
}

@media screen and (max-width: 600px) {
  .MainPage_scopeLogo__1OVth {
    background-size: 65%;
    margin: auto;
  }
  .MainPage_scopeLogo__1OVth:hover {
    background-size: 65%;
    margin:auto;
  }
  .MainPage_scopegradientorb1Icon__cKKPK {
    right: 0;
    width: 100%;
  }
  .MainPage_scopegradientorb2Icon__1DMPN {
    left: 0;
    width: 100%;
  }
  .MainPage_learnBuildCode__hg2Wg {
    position: absolute;
    top: 5.5%;
    left: 11%;
    margin: auto;
    font-size: 35px;
    font-weight: 600;
    color: var(--color-white);
    text-align: left;
    display: inline-block;
    width: 90%;
    /* height: 74px; */
  }
  .MainPage_applicationsForTheContainer__1Yp5p {
    position: absolute;
    top: 6.34%;
    left: 11%;
    font-size: 40px;
    font-weight: 500;
    color: var(--color-white);
    text-align: left;
    display: inline-block;
    width: 80%;
    height: 135px;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    /* -webkit-text-stroke: 1px #000; */
  }

  .MainPage_applicationsForThe__1kc6u {
    width: 100%;
  }

  .MainPage_applicationAtContainer__1SsbT {
    left: 31%;
    
  }
  p.MainPage_applicationsForThe__1kc6u {
    max-width: 80%;
    width: 70%;
    margin: auto;
    overflow-y: hidden;
  }
  body.MainPage_applicationsForThe__1kc6u {
    /* visibility: hidden; */
    left: 0%;
    margin: 0;
    font-size: 22px;
    width: 70%;
  }
  .MainPage_applyNow__3wq4W {
    margin: auto;
    top: 9%;
    padding-left: 25%;
    padding-right: 25%;
    margin-top: 70px;
  }
  .MainPage_applyNow__3wq4W:hover {
    margin: auto;
    top: 9%;
    padding-left: 25%;
    padding-right: 25%;
  }
  .MainPage_linkStack__2tdPv { 
    visibility: hidden;
  }
  .MainPage_githubButtonIcon__35U6_ {
    visibility: visible;
    left: -24%;
    top: 129.5%;
    color: purple;
  }
  .MainPage_facebookButtonIcon__2ZAHo {
    visibility: visible;
    left: 16%;
    top: 130%;
  }
  .MainPage_instagramButtonIcon__1eLg_ {
    visibility: visible;
    left: 56%;
    top: 129.5%;
  }
  .MainPage_jakepointingIcon__1qg9c {
    visibility: hidden;
  }
  .MainPage_paulphotoIcon__2cHG1 {
    visibility: hidden;
  }
  .MainPage_hackathonPhotoIcon__2FYyo {
    visibility: hidden;
  }
  .MainPage_rollercoasterphoto1Icon__2bI9H {
    visibility: hidden;
  }
  .MainPage_menuBar__2e_8S { 
    visibility: hidden;
  }
  .MainPage_faq4__2uIFQ {
    visibility: hidden;
  }
  .MainPage_cohort4__1dO50 {
    visibility: hidden;
  }
  .MainPage_about3__1aYi- {
    visibility: hidden;
  }
  .MainPage_learn4__1X50s {
    visibility: hidden;
  }
  .MainPage_sponsor2__2YhrH {
    left: 35%;
  }
  .MainPage_scopeCup2__3yZ05 {
    width: 30%;
    left: 65%;
  }
  .MainPage_buildAndLearn__1lmOT {
    width: 80%;
    margin: auto;
  }
  .MainPage_learnSymbolIcon__3Qq8y {
    position: absolute;
    top: -145%;
    left: 44%;
    margin: auto;
    width: 128px;
    height: 105px;
    object-fit: cover;
  }
  .MainPage_learn3__1kmcR {
      position: absolute;
      top: -110%;
      left: 44.5%;
      margin: auto;
      width: 128px;
      height: 105px;
      object-fit: cover;
  }
  .MainPage_learnACuttingEdge__2uTDa {
    position: absolute;
    top: -95%;
    left: 25%;
    width: 50%;
    height: 105px;
    object-fit: cover;
}
.MainPage_bondSymbolIcon__1xZCV {
  position: absolute;
  top: -70%;
  left: 46%;
  margin: auto;
  object-fit: cover;
}
.MainPage_collaborate__33Vzc {
    position: absolute;
    top: -28%;
    left: 42.5%;
    margin: auto;
    width: 128px;
    height: 105px;
    object-fit: cover;
}
.MainPage_teamUpIdeate__PbH8s {
  position: absolute;
  top: -13%;
  left: 25%;
  width: 50%;
  height: 105px;
  object-fit: cover;
}
.MainPage_belongSymbolIcon__23E46 {
  position: absolute;
  top: 20%;
  left: 44%;
  margin: auto;
  object-fit: cover;
}
.MainPage_belong__HazdV {
    position: absolute;
    top: 65%;
    left: 44.5%;
    margin: auto;
    width: 128px;
    height: 105px;
    object-fit: cover;
}
.MainPage_joinATightKnit___qWJi {
  position: absolute;
  top: 80%;
  left: 25%;
  width: 50%;
  height: 105px;
  object-fit: cover;
}
.MainPage_atScopeWe__1eWct {
  top: 3047px;
  margin: auto;
  left: 5%;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  width: 90%;
}
.MainPage_leftCurriculumColumn__3aegI {
  visibility: hidden;
}
.MainPage_curriculum__350JJ {
  position: absolute;
  top: 3350px;
  left: -80%;
  width: 1038px;
  height: 858px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 97px;
  gap: 97px;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
}
.MainPage_fall2020Virtual__3mBIt{ 
  margin-left: 15%;
  width: 100%;
}
.MainPage_froodphoto1Icon__3tC4U {
  visibility: hidden;
}
.MainPage_eachSemesterWe__33axJ {
  width: 90%;
  left: 5%;
  top: 4600px;
}
.MainPage_frequentlyAskedQuestions__1xer4 {
  font-size: 40px;
}
.MainPage_globewShadow1Icon__2Ad9b {
  width: 100%;
}
.MainPage_theCohort__2G-Uj {
  top: 4470px;
  left: calc(50% - 475.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
}
.MainPage_qWhatMakes__1izkM {
  visibility: hidden;
}
.MainPage_weAreAn__3h_NY {
  visibility: hidden;
}
.MainPage_qHowMany__1OuHy {
  visibility: hidden;
}
.MainPage_qWhatIs__hZTKG {
  visibility: hidden;
}
.MainPage_weHaveTwo__3I6R_ {
  visibility: hidden;
}
.MainPage_applicationProcess__oNyfE {
  left: 40%;
}
.MainPage_qWhatDoes__16C2R {
  left: 33.5%;
}
.MainPage_scopeLooksFor__VDiSD {
  width: 40%;
  font-size: 22px;
  left: 30%;
}
.MainPage_qWhatDoes1__382Uu {
  left: 32%;
}
.MainPage_scopeLooksFor__VDiSD {
  width: 39%;
  font-size: 22px;
  left: 31%;
}
b.MainPage_applicationsForThe__1kc6u {
  /* visibility: hidden; */
}
.MainPage_qWhoIs__3VvFo {
  left: 39.5%;
}
.MainPage_scopeIsTo__3X5yn {
  left: 31%;
  font-size: 22px;
  width: 39%;
}
.MainPage_qDoI__3GERw {
  left: 35%;
}
.MainPage_sinceOurCurriculum__2SHj8 {
  left: 31%;
  font-size: 22px;
  width: 39%;
}
.MainPage_googleIcon__n4X1n {
  visibility: hidden;
}
.MainPage_microsoftIcon__3n9Hu {
  visibility: hidden;
}
.MainPage_metaLogoIcon__1rTNp {
  visibility: hidden;
}
.MainPage_coinbaseIcon__61xeS {
  visibility: hidden;
}
.MainPage_ourExecutiveBoard__xgrfG {
  width: 100%;
  /* font-size: 20px; */
  left: 0%;
}
.MainPage_eboardPhotos__KeumA {
  max-width: 100%;
  top: 7035px;
  left: -4%;
}
.MainPage_ourNetworkOf__2TOSX {
  width: 100%;
  left: 0%;
}
}



@media screen and (max-width: 900px) {
  .MainPage_scopeLogo__1OVth {
    background-size: 65%;
    margin: auto;
  }
  .MainPage_scopeLogo__1OVth:hover {
    background-size: 65%;
    margin:auto;
  }
  .MainPage_scopegradientorb1Icon__cKKPK {
    right: 0;
    width: 100%;
  }
  .MainPage_scopegradientorb2Icon__1DMPN {
    left: 0;
    width: 100%;
  }
  .MainPage_learnBuildCode__hg2Wg {
    position: absolute;
    top: 5.5%;
    left: 11%;
    margin: auto;
    font-size: 35px;
    font-weight: 600;
    color: var(--color-white);
    text-align: left;
    display: inline-block;
    width: 90%;
    /* height: 74px; */
  }
  .MainPage_applicationsForTheContainer__1Yp5p {
    position: absolute;
    top: 6.34%;
    left: 11%;
    font-size: 20px;
    font-weight: 300;
    color: var(--color-white);
    text-align: left;
    display: inline-block;
    width: 80%;
    height: 135px;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    /* -webkit-text-stroke: 1px #000; */
  }

  .MainPage_applicationsForThe__1kc6u {
    width: 100%;
  }

  .MainPage_applicationAtContainer__1SsbT {
    left: 31%;
    
  }
  p.MainPage_applicationsForThe__1kc6u {
    max-width: 90%;
    width: 90%;
    font-size: 28px;
    margin: 0;
    overflow-y: hidden;
  }
  body.MainPage_applicationsForThe__1kc6u {
    /* visibility: hidden; */
    left: 20%;
    margin-left: 2%;
    font-size: 22px;
    width: 80%;
  }
  .MainPage_applyNow__3wq4W {
    margin: auto;
    top: 9%;
    padding-left: 25%;
    padding-right: 25%;
    margin-top: 70px;
  }
  .MainPage_applyNow__3wq4W:hover {
    margin: auto;
    top: 9%;
    padding-left: 25%;
    padding-right: 25%;
    margin-top: 70px;
  }
  .MainPage_linkStack__2tdPv { 
    visibility: hidden;
  }
  .MainPage_githubButtonIcon__35U6_ {
    visibility: visible;
    left: -24%;
    top: 129.5%;
    color: purple;
  }
  .MainPage_facebookButtonIcon__2ZAHo {
    visibility: visible;
    left: 16%;
    top: 130%;
  }
  .MainPage_instagramButtonIcon__1eLg_ {
    visibility: visible;
    left: 56%;
    top: 129.5%;
  }
  .MainPage_jakepointingIcon__1qg9c {
    visibility: hidden;
  }
  .MainPage_paulphotoIcon__2cHG1 {
    visibility: hidden;
  }
  .MainPage_hackathonPhotoIcon__2FYyo {
    visibility: hidden;
  }
  .MainPage_rollercoasterphoto1Icon__2bI9H {
    visibility: hidden;
  }
  .MainPage_menuBar__2e_8S { 
    visibility: hidden;
  }
  .MainPage_faq4__2uIFQ {
    visibility: hidden;
  }
  .MainPage_cohort4__1dO50 {
    visibility: hidden;
  }
  .MainPage_about3__1aYi- {
    visibility: hidden;
  }
  .MainPage_learn4__1X50s {
    visibility: hidden;
  }
  .MainPage_sponsor2__2YhrH {
    left: 35%;
  }
  .MainPage_scopeCup2__3yZ05 {
    width: 30%;
    left: 65%;
  }
  .MainPage_buildAndLearn__1lmOT {
    width: 65%;
    margin: auto;
  }
  .MainPage_learnSymbolIcon__3Qq8y {
    position: absolute;
    top: -145%;
    left: 44%;
    margin: auto;
    width: 128px;
    height: 105px;
    object-fit: cover;
  }
  .MainPage_learn3__1kmcR {
      position: absolute;
      top: -110%;
      left: 44.5%;
      margin: auto;
      width: 128px;
      height: 105px;
      object-fit: cover;
  }
  .MainPage_learnACuttingEdge__2uTDa {
    position: absolute;
    top: -95%;
    left: 25%;
    width: 50%;
    height: 105px;
    object-fit: cover;
}
.MainPage_bondSymbolIcon__1xZCV {
  position: absolute;
  top: -70%;
  left: 46%;
  margin: auto;
  object-fit: cover;
}
.MainPage_collaborate__33Vzc {
    position: absolute;
    top: -28%;
    left: 42.5%;
    margin: auto;
    width: 128px;
    height: 105px;
    object-fit: cover;
}
.MainPage_teamUpIdeate__PbH8s {
  position: absolute;
  top: -13%;
  left: 25%;
  width: 50%;
  height: 105px;
  object-fit: cover;
}
.MainPage_belongSymbolIcon__23E46 {
  position: absolute;
  top: 20%;
  left: 44%;
  margin: auto;
  object-fit: cover;
}
.MainPage_belong__HazdV {
    position: absolute;
    top: 65%;
    left: 44.5%;
    margin: auto;
    width: 128px;
    height: 105px;
    object-fit: cover;
}
.MainPage_joinATightKnit___qWJi {
  position: absolute;
  top: 80%;
  left: 25%;
  width: 50%;
  height: 105px;
  object-fit: cover;
}
.MainPage_atScopeWe__1eWct {
  top: 3047px;
  margin: auto;
  left: 3%;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  width: 95%;
}
.MainPage_leftCurriculumColumn__3aegI {
  visibility: hidden;
}
.MainPage_curriculum__350JJ {
  position: absolute;
  top: 3350px;
  left: -90%;
  margin: auto;
  width: 1038px;
  height: 858px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 97px;
  gap: 97px;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
}
.MainPage_fall2020Virtual__3mBIt{ 
  margin-left: 15%;
  width: 100%;
}
.MainPage_froodphoto1Icon__3tC4U {
  visibility: hidden;
}
.MainPage_eachSemesterWe__33axJ {
  width: 90%;
  left: 5%;
  top: 4600px;
}
.MainPage_frequentlyAskedQuestions__1xer4 {
  font-size: 40px;
}
.MainPage_globewShadow1Icon__2Ad9b {
  width: 100%;
}
.MainPage_theCohort__2G-Uj {
  top: 4470px;
  left: calc(50% - 475.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
}
.MainPage_qWhatMakes__1izkM {
  visibility: hidden;
}
.MainPage_weAreAn__3h_NY {
  visibility: hidden;
}
.MainPage_qHowMany__1OuHy {
  visibility: hidden;
}
.MainPage_qWhatIs__hZTKG {
  visibility: hidden;
}
.MainPage_weHaveTwo__3I6R_ {
  visibility: hidden;
}
.MainPage_applicationProcess__oNyfE {
  left: 40%;
}
.MainPage_qWhatDoes__16C2R {
  left: 33.5%;
}
.MainPage_scopeLooksFor__VDiSD {
  width: 40%;
  font-size: 22px;
  left: 30%;
}
.MainPage_qWhatDoes1__382Uu {
  left: 32%;
}
.MainPage_scopeLooksFor__VDiSD {
  width: 35%;
  font-size: 22px;
  left: 32%;
}
b.MainPage_applicationsForThe__1kc6u {
  /* visibility: hidden; */
}
.MainPage_qWhoIs__3VvFo {
  left: 39.5%;
}
.MainPage_scopeIsTo__3X5yn {
  left: 32%;
  font-size: 22px;
  width: 34%;
}
.MainPage_qDoI__3GERw {
  left: 35%;
}
.MainPage_sinceOurCurriculum__2SHj8 {
  left: 32%;
  font-size: 22px;
  width: 35%;
}
.MainPage_googleIcon__n4X1n {
  visibility: hidden;
}
.MainPage_microsoftIcon__3n9Hu {
  visibility: hidden;
}
.MainPage_metaLogoIcon__1rTNp {
  visibility: hidden;
}
.MainPage_coinbaseIcon__61xeS {
  visibility: hidden;
}
.MainPage_ourExecutiveBoard__xgrfG {
  width: 100%;
  /* font-size: 20px; */
  left: 0%;
}
.MainPage_eboardPhotos__KeumA {
  max-width: 100%;
  top: 7035px;
  left: -4%;
}
.MainPage_ourNetworkOf__2TOSX {
  width: 100%;
  left: 0%;
}
.MainPage_middle__35W-2 {
  font-size: 18px;
}
}

@media screen and (max-width: 400px) {
  .MainPage_scopeLogo__1OVth {
    background-size: 60%;
    margin: auto;
  }
  .MainPage_scopeLogo__1OVth:hover {
    background-size: 60%;
    margin:auto;
  }
  .MainPage_learnBuildCode__hg2Wg {
    position: absolute;
    top: 5.5%;
    left: 11%;
    margin: auto;
    font-size: 30px;
    font-weight: 600;
    color: var(--color-white);
    text-align: left;
    display: inline-block;
    width: 90%;
    /* height: 74px; */
  }
  .MainPage_theCountdown__2ub2g {
    font-size: 18px;
  }
  .MainPage_linkedinLogoIcon__2ySXn {
    left: 25%;
  }
  .MainPage_middle__35W-2 {
    font-size: 15px;
  }
  .MainPage_frequentlyAskedQuestions__1xer4 {
    font-size: 35px;
  }
}
.ScopeCup_colorstripe21Icon__PKYlP {
  position: absolute;
  /* width: 49.88%; */
  top: 7%;
  left: -10%;
  overflow: visible;
  height: 90%;
  /* object-fit: cover; */
}
.ScopeCup_image12Icon__EE9Rr,
.ScopeCup_image14Icon__O4sA7,
.ScopeCup_image16Icon__Upu3s,
.ScopeCup_image8Icon__387Qg {
  position: absolute;
  top: 0;
  left: 0;
  width: 109px;
  height: 110px;
  object-fit: cover;
}
.ScopeCup_image12Icon__EE9Rr,
.ScopeCup_image14Icon__O4sA7,
.ScopeCup_image16Icon__Upu3s {
  top: 281px;
}
.ScopeCup_image14Icon__O4sA7,
.ScopeCup_image16Icon__Upu3s {
  top: 141px;
}
.ScopeCup_image14Icon__O4sA7 {
  top: 422px;
}
.ScopeCup_image13Icon__1Vx3j,
.ScopeCup_image15Icon__1PtPS,
.ScopeCup_image17Icon__1FTd3,
.ScopeCup_image9Icon__ODRPx {
  position: absolute;
  top: 0;
  left: 123px;
  width: 110px;
  height: 110px;
  object-fit: cover;
}
.ScopeCup_image13Icon__1Vx3j,
.ScopeCup_image15Icon__1PtPS,
.ScopeCup_image17Icon__1FTd3 {
  top: 281px;
}
.ScopeCup_image15Icon__1PtPS,
.ScopeCup_image17Icon__1FTd3 {
  top: 141px;
}
.ScopeCup_image15Icon__1PtPS {
  top: 422px;
}
.ScopeCup_div__3phPR {
  position: absolute;
  top: 41px;
  right: 0;
  display: inline-block;
  width: 120px;
  height: 28px;
}
.ScopeCup_p__BqNCg {
  margin: 0;
}
.ScopeCup_blankLine__1QBYE {
  margin: 0;
  font-size: var(--font-size-5xl);
}
.ScopeCup_div1__31KHv,
.ScopeCup_div2__2TGAQ,
.ScopeCup_div3__w1Ttr {
  position: absolute;
  top: 182px;
  right: 0;
  display: inline-block;
  width: 120px;
  height: 28px;
}
.ScopeCup_div2__2TGAQ,
.ScopeCup_div3__w1Ttr {
  top: 322px;
}
.ScopeCup_div3__w1Ttr {
  top: 463px;
}
.ScopeCup_xxXxContainer__3iyXU,
.ScopeCup_xxXxContainer1__2K4HK,
.ScopeCup_xxXxContainer2__1IafD,
.ScopeCup_xxXxContainer3__2ZAX9 {
  position: absolute;
  top: 41px;
  left: 297px;
  display: inline-block;
  width: 158px;
  height: 28px;
}
.ScopeCup_xxXxContainer1__2K4HK,
.ScopeCup_xxXxContainer2__1IafD,
.ScopeCup_xxXxContainer3__2ZAX9 {
  top: 182px;
}
.ScopeCup_xxXxContainer2__1IafD,
.ScopeCup_xxXxContainer3__2ZAX9 {
  top: 322px;
}
.ScopeCup_xxXxContainer3__2ZAX9 {
  top: 463px;
}
.ScopeCup_image8Parent__1gYPl {
  position: absolute;
  zoom: 0.85;
  top: 30%;
  left: 10.5%;
  width: 733px;
  height: 532px;
}
.ScopeCup_scopecup3d1Icon__3v43T,
.ScopeCup_scopecup3d2Icon__3daXD {
  position: absolute;
  top: 207px;
  right: 110px;
  width: 467px;
  height: 120px;
  object-fit: cover;
}
.ScopeCup_scopecup3d2Icon__3daXD {
  top: 357px;
  right: 123px;
  width: 284px;
  height: 119px;
}
.ScopeCup_menuBarChild__1Tujx {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-linen);
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 1743px;
  height: 103px;
}
.ScopeCup_purpleinf1Icon__X0RtV {
  position: absolute;
  top: 26px;
  left: 68.43px;
  width: 103.65px;
  height: 50px;
  object-fit: cover;
  cursor: pointer;
}
.ScopeCup_aboutLearnCohort__2MA2K {
  position: absolute;
  top: 38px;
  left: 262.66px;
  color: var(--color-limegreen);
  display: none;
  width: 1710.8px;
  height: 65px;
}
.ScopeCup_home__pX31D,
.ScopeCup_login__2Pi2B,
.ScopeCup_menuBar__pKocI,
.ScopeCup_stats__iuFnG {
  position: absolute;
  display: inline-block;
  height: 65px;
}
.ScopeCup_home__pX31D {
  top: 37px;
  left: 1129px;
  width: 96px;
  cursor: pointer;
}
.ScopeCup_login__2Pi2B,
.ScopeCup_menuBar__pKocI,
.ScopeCup_stats__iuFnG {
  top: 38px;
  left: 1342px;
  width: 102px;
}
.ScopeCup_login__2Pi2B,
.ScopeCup_menuBar__pKocI {
  top: 37px;
  left: 1561px;
  width: 103px;
}
.ScopeCup_menuBar__pKocI {
  top: 0;
  left: -8px;
  width: 1743px;
  height: 103px;
  display: none;
  color: var(--color-blueviolet);
}
.ScopeCup_screenshot20230713At923__3jShH {
  position: absolute;
  top: 548px;
  right: 91px;
  width: 563px;
  height: 318px;
  object-fit: cover;
}
.ScopeCup_menuBarItem__NzEIG {
  position: absolute;
  top: 26px;
  left: 63px;
  background-color: var(--color-linen);
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 1727px;
  height: 103px;
  display: none;
}
.ScopeCup_aboutLearnCohort1__1onZo {
  position: absolute;
  top: 12px;
  left: 193px;
  display: none;
  width: 1700px;
  height: 65px;
}
.ScopeCup_menuBar1__2SPVY {
  position: relative;
  height: 38px;
  background-image: url(/menu-bar@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  transition: all .3s ease-in-out;
}
.ScopeCup_menuBar1__2SPVY:hover {
  background-image: url(/silverInfinity.png);
  width: 80px;
  -webkit-filter: drop-shadow(2px 4px 1px var(--color-blueviolet));
          filter: drop-shadow(2px 4px 1px var(--color-blueviolet));
  transition: all .3s ease-in-out;
}
.ScopeCup_home1__3bKnv {
  position: relative;
  font-size: var(--font-size-5xl);
  color: var(--color-blueviolet);
  text-align: center;
  display: inline-block;
  width: 78px;
  height: 67px;
  flex-shrink: 0;
}

.ScopeCup_menuBarParent__U-KNh {
  position: absolute;
  top: 2%;
  left: 5%;
  align-items: center;
  justify-content: center;
  grid-gap: var(--gap-sm);
  gap: var(--gap-sm);
  color: var(--color-limegreen);
}
.ScopeCup_scopeCup__1DhRN {
  position: relative;
  background-color: var(--color-linen);
  width: 100%;
  height: 1060px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
  font-family: var(--font-inter);
  zoom: 0.8;
}

.SponsorPage_globewShadow1Icon__32gB7,
.SponsorPage_rectangleIcon__zQXz9,
.SponsorPage_rectangleIcon1__Zpv6l,
.SponsorPage_rectangleIcon2__3y7YG {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 741.4px;
  object-fit: cover;
}
.SponsorPage_globewShadow1Icon__32gB7 {
  top: -45%;
  height: 100%;
  width: 100%;
  left: 2%;
  overflow: visible;
}
.SponsorPage_rectangleIcon__zQXz9,
.SponsorPage_rectangleIcon1__Zpv6l,
.SponsorPage_rectangleIcon2__3y7YG {
  height: 75.83%;
  width: 42.06%;
  top: 0.34%;
  right: 39.13%;
  bottom: 23.83%;
  left: 18.81%;
  max-height: 100%;
}
.SponsorPage_rectangleIcon1__Zpv6l,
.SponsorPage_rectangleIcon2__3y7YG {
  height: 73.22%;
  width: 16.07%;
  top: 0;
  right: 83.93%;
  bottom: 26.78%;
  left: 0;
}
.SponsorPage_rectangleIcon2__3y7YG {
  height: 98.14%;
  width: 36.14%;
  top: 2.17%;
  right: -0.08%;
  bottom: -0.31%;
  left: 63.95%;
}
.SponsorPage_scopeOffWhite3d2__3AaoT {
  position: absolute;
  top: 0;
  right: 0;
  width: 761px;
  height: 197px;
  overflow: hidden;
}
.SponsorPage_welcomeToScope__1kuS_,
.SponsorPage_whiteScopeLogo__2yTDP {
  position: absolute;
  top: 329px;
  left: calc(50% - 362px);
  width: 761px;
  height: 210px;
}
.SponsorPage_welcomeToScope__1kuS_ {
  top: 136px;
  left: calc(50% - 348px);
  font-size: var(--font-size-21xl);
  display: inline-block;
  color: var(--color-linen);
  width: 734px;
  height: 146px;
}
.SponsorPage_pastSponsors__3yjhG,
.SponsorPage_sponsorshipAmount50050__1nM0n {
  position: absolute;
  top: 668px;
  left: calc(50% - 369px);
  display: inline-block;
  width: 737px;
  height: 146px;
}
.SponsorPage_pastSponsors__3yjhG {
  top: 984px;
  height: 66px;
}
.SponsorPage_selectAnAmount__1905E {
  margin: 0;
}
.SponsorPage_selectAnAmountContainer__9Jle2 {
  top: 579px;
  left: calc(50% - 450px);
  color: var(--color-black);
  width: 900px;
  height: 81px;
}
.SponsorPage_anayModyusc__p7ifH,
.SponsorPage_ifYouOrContainer__qcgNo,
.SponsorPage_selectAnAmountContainer__9Jle2 {
  position: absolute;
  font-size: var(--font-size-5xl);
  display: inline-block;
}
.SponsorPage_ifYouOrContainer__qcgNo {
  top: 1346px;
  left: calc(50% - 450px);
  color: var(--color-black);
  width: 900px;
  height: 93px;
}
.SponsorPage_anayModyusc__p7ifH {
  top: 1456px;
  left: calc(50% - 252px);
  width: 542px;
  height: 73px;
}
.SponsorPage_sponsorPageChild__3ycbs {
  position: absolute;
  top: 838px;
  left: calc(50% - 165px);
  border-radius: 32px;
  background-color: var(--color-blueviolet);
  width: 329px;
  height: 79px;
}
.SponsorPage_sponsorPageItem__3wEvb {
  position: absolute;
  top: 741px;
  left: calc(50% - 76px);
  background-color: #d9d9d9;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 144px;
  height: 57px;
}
.SponsorPage_janeStreetLogoIcon__36pkk {
  position: absolute;
  top: 1070px;
  left: calc(50% - 682px);
  width: 305px;
  height: 115px;
  object-fit: cover;
}
.SponsorPage_rippleLogoIcon__3MQpe {
  position: absolute;
  top: 1085px;
  left: calc(50% - 350px);
  width: 305px;
  height: 88px;
  object-fit: cover;
}
.SponsorPage_microsoftLogoIcon__2027n {
  position: absolute;
  top: 1090px;
  left: calc(50% + 0px);
  width: 346px;
  height: 74px;
  object-fit: cover;
}
.SponsorPage_metaLogoIcon__3aUBB {
  position: absolute;
  top: 1067px;
  left: calc(50% + 361px);
  width: 317px;
  height: 103px;
  object-fit: cover;
}
.SponsorPage_githubLogoIcon__3fZjU {
  position: absolute;
  top: 1190px;
  left: calc(50% - 522px);
  width: 320px;
  height: 87px;
  object-fit: cover;
}
.SponsorPage_qualcommLogoIcon__1cs1F {
  position: absolute;
  top: 1205px;
  left: calc(50% - 156px);
  width: 324px;
  height: 59px;
  object-fit: cover;
}
.SponsorPage_strivrLogoIcon__3Psl5 {
  position: absolute;
  top: 1205px;
  left: calc(50% + 235px);
  width: 368px;
  height: 59px;
  object-fit: cover;
}
.SponsorPage_purplescope__2iSbH,
.SponsorPage_scopePurple3d1Icon__375-E {
  position: absolute;
  width: 790px;
  height: 205px;
}
.SponsorPage_scopePurple3d1Icon__375-E {
  top: 0;
  left: 0;
  object-fit: cover;
}
.SponsorPage_purplescope__2iSbH {
  top: 322px;
  left: 469px;
  display: none;
}
.SponsorPage_sponsorEnteredAmount__3VhFV {
  position: absolute;
  top: 868px;
  left: calc(50% - 218px);
  font-size: 17px;
  font-weight: 600;
  color: var(--color-linen);
  display: inline-block;
  width: 435px;
  height: 28px;
}
.SponsorPage_menuBarChild__3iQ8O {
  position: absolute;
  top: 26px;
  left: 63px;
  background-color: var(--color-linen);
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 1727px;
  height: 103px;
  display: none;
}
.SponsorPage_aboutLearnCohort__2tKGj {
  position: absolute;
  top: 12px;
  left: 193px;
  display: none;
  width: 1700px;
  height: 65px;
}
.SponsorPage_home__3sFC6,
.SponsorPage_menuBar__2NxtN {
  position: relative;
}
.SponsorPage_menuBar__2NxtN {
  position: relative;
  height: 38px;
  background-image: url(/menu-bar@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  transition: all .3s ease-in-out;
}
.SponsorPage_menuBar__2NxtN:hover {
  background-image: url(/silverInfinity.png);
  width: 80px;
  -webkit-filter: drop-shadow(2px 4px 1px var(--color-blueviolet));
          filter: drop-shadow(2px 4px 1px var(--color-blueviolet));
  transition: all .3s ease-in-out;
}
.SponsorPage_home__3sFC6 {
  font-size: var(--font-size-5xl);
  color: var(--color-blueviolet);
  text-align: center;
  display: inline-block;
  width: 78px;
  height: 67px;
  flex-shrink: 0;
}
.SponsorPage_menuBarParent__1A_Pg {
  position: absolute;
  top: 2%;
  left: 5%;
  align-items: center;
  justify-content: center;
  grid-gap: var(--gap-sm);
  gap: var(--gap-sm);
  color: var(--color-limegreen);
}
.SponsorPage_sponsorPage__1PeUV {
  position: relative;
  background-color: var(--color-linen);
  width: 100%;
  height: 1590px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--color-blueviolet);
  font-family: var(--font-inter);
  zoom: 0.67;
}

body {
  margin: 0;
  line-height: normal;
  box-sizing: border-box;
  font-family: 'Raleway';
}

:root {
  /* fonts */
  --font-inter: Inter;
  --font-interstatemono: InterstateMono;

  /* font sizes */
  --font-size-13xl: 32px;
  --font-size-5xl: 24px;
  --font-size-31xl: 50px;
  --font-size-xl: 20px;
  --font-size-21xl: 40px;

  /* Colors */
  --color-linen: #f4f2eb;
  --color-black: #000;
  --color-blueviolet: #6734d1;
  --color-white: #fff;

  /* border radiuses */
  --br-11xl: 30px;
}

:root {
	--radius: 200vmin;
	--ring-width: calc(2 * var(--radius) + 140vmin);
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

h1, h2 {
	font-weight: normal;
}

.desktop.CohortApplication > * {
	-webkit-transform-origin: 500vmin 500vmin;
	        transform-origin: 500vmin 500vmin;
}

.desktop.CohortApplication {
	width: 1000vmin;
	height: 1000vmin;
}

.CohortApplication {
	background-color: #F4F2EB;
}

form {
	transition: scale .5s ease-out;
}

form.submitted {
	-webkit-animation-name: shrink-out;
	        animation-name: shrink-out;
	-webkit-animation-delay: 2.75s;
	        animation-delay: 2.75s;
	-webkit-animation-duration: 1.75s;
	        animation-duration: 1.75s;
	-webkit-animation-timing-function: cubic-bezier(1,.06,.86,.31);
	        animation-timing-function: cubic-bezier(1,.06,.86,.31);
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

.form-field {
	display: flex;
	flex-direction: column;
	justify-content: center;
	
	color: white;
	color: #F4F2EB;
	border: 10px solid #321d60;
	background: linear-gradient(#41218670 0%, #41218670 100%), url(/static/media/twisty-blob.e79ca670.png);
}

.mobile.form-field {
	padding: 40px 30px;
	border-width: 2px;
	border-radius: 10px;
	margin: 5px;
}

.desktop.form-field {
	position: absolute;
	border-radius: 50%;
	width: 50vmin;
	height: 50vmin;
	-webkit-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
	min-width: 30em;
	min-height: 30em;
	padding: 6em;
	top: 500vmin;
	left: 500vmin;
	translate: -50% -50%;
	align-items: center;
	font-size: 1.25rem;
}

.mobile.form-field .form-nav {
	display: none;
}

.desktop.form-field:nth-of-type(1) {
	-webkit-transform: rotate(-90.0deg) translate(200vmin) rotate(90.0deg);
	        transform: rotate(-90.0deg) translate(200vmin) rotate(90.0deg);
	-webkit-transform: rotate(-90.0deg) translate(var(--radius)) rotate(90.0deg);
	        transform: rotate(-90.0deg) translate(var(--radius)) rotate(90.0deg);
}

.desktop.form-field:nth-of-type(2) {
	-webkit-transform: rotate(-68.82deg) translate(200vmin) rotate(68.82deg);
	        transform: rotate(-68.82deg) translate(200vmin) rotate(68.82deg);
	-webkit-transform: rotate(-68.82deg) translate(var(--radius)) rotate(68.82deg);
	        transform: rotate(-68.82deg) translate(var(--radius)) rotate(68.82deg);
}

.desktop.form-field:nth-of-type(3) {
	-webkit-transform: rotate(-47.65deg) translate(200vmin) rotate(47.65deg);
	        transform: rotate(-47.65deg) translate(200vmin) rotate(47.65deg);
	-webkit-transform: rotate(-47.65deg) translate(var(--radius)) rotate(47.65deg);
	        transform: rotate(-47.65deg) translate(var(--radius)) rotate(47.65deg);
}

.desktop.form-field:nth-of-type(4) {
	-webkit-transform: rotate(-26.47deg) translate(200vmin) rotate(26.47deg);
	        transform: rotate(-26.47deg) translate(200vmin) rotate(26.47deg);
	-webkit-transform: rotate(-26.47deg) translate(var(--radius)) rotate(26.47deg);
	        transform: rotate(-26.47deg) translate(var(--radius)) rotate(26.47deg);
}

.desktop.form-field:nth-of-type(5) {
	-webkit-transform: rotate(-5.29deg) translate(200vmin) rotate(5.29deg);
	        transform: rotate(-5.29deg) translate(200vmin) rotate(5.29deg);
	-webkit-transform: rotate(-5.29deg) translate(var(--radius)) rotate(5.29deg);
	        transform: rotate(-5.29deg) translate(var(--radius)) rotate(5.29deg);
}

.desktop.form-field:nth-of-type(6) {
	-webkit-transform: rotate(15.88deg) translate(200vmin) rotate(-15.88deg);
	        transform: rotate(15.88deg) translate(200vmin) rotate(-15.88deg);
	-webkit-transform: rotate(15.88deg) translate(var(--radius)) rotate(-15.88deg);
	        transform: rotate(15.88deg) translate(var(--radius)) rotate(-15.88deg);
}

.desktop.form-field:nth-of-type(7) {
	-webkit-transform: rotate(37.06deg) translate(200vmin) rotate(-37.06deg);
	        transform: rotate(37.06deg) translate(200vmin) rotate(-37.06deg);
	-webkit-transform: rotate(37.06deg) translate(var(--radius)) rotate(-37.06deg);
	        transform: rotate(37.06deg) translate(var(--radius)) rotate(-37.06deg);
}

.desktop.form-field:nth-of-type(8) {
	-webkit-transform: rotate(58.24deg) translate(200vmin) rotate(-58.24deg);
	        transform: rotate(58.24deg) translate(200vmin) rotate(-58.24deg);
	-webkit-transform: rotate(58.24deg) translate(var(--radius)) rotate(-58.24deg);
	        transform: rotate(58.24deg) translate(var(--radius)) rotate(-58.24deg);
}

.desktop.form-field:nth-of-type(9) {
	-webkit-transform: rotate(79.41deg) translate(200vmin) rotate(-79.41deg);
	        transform: rotate(79.41deg) translate(200vmin) rotate(-79.41deg);
	-webkit-transform: rotate(79.41deg) translate(var(--radius)) rotate(-79.41deg);
	        transform: rotate(79.41deg) translate(var(--radius)) rotate(-79.41deg);
}

.desktop.form-field:nth-of-type(10) {
	-webkit-transform: rotate(100.59deg) translate(200vmin) rotate(-100.59deg);
	        transform: rotate(100.59deg) translate(200vmin) rotate(-100.59deg);
	-webkit-transform: rotate(100.59deg) translate(var(--radius)) rotate(-100.59deg);
	        transform: rotate(100.59deg) translate(var(--radius)) rotate(-100.59deg);
}

.desktop.form-field:nth-of-type(11) {
	-webkit-transform: rotate(121.76deg) translate(200vmin) rotate(-121.76deg);
	        transform: rotate(121.76deg) translate(200vmin) rotate(-121.76deg);
	-webkit-transform: rotate(121.76deg) translate(var(--radius)) rotate(-121.76deg);
	        transform: rotate(121.76deg) translate(var(--radius)) rotate(-121.76deg);
}

.desktop.form-field:nth-of-type(12) {
	-webkit-transform: rotate(142.94deg) translate(200vmin) rotate(-142.94deg);
	        transform: rotate(142.94deg) translate(200vmin) rotate(-142.94deg);
	-webkit-transform: rotate(142.94deg) translate(var(--radius)) rotate(-142.94deg);
	        transform: rotate(142.94deg) translate(var(--radius)) rotate(-142.94deg);
}

.desktop.form-field:nth-of-type(13) {
	-webkit-transform: rotate(164.12deg) translate(200vmin) rotate(-164.12deg);
	        transform: rotate(164.12deg) translate(200vmin) rotate(-164.12deg);
	-webkit-transform: rotate(164.12deg) translate(var(--radius)) rotate(-164.12deg);
	        transform: rotate(164.12deg) translate(var(--radius)) rotate(-164.12deg);
}

.desktop.form-field:nth-of-type(14) {
	-webkit-transform: rotate(185.29deg) translate(200vmin) rotate(-185.29deg);
	        transform: rotate(185.29deg) translate(200vmin) rotate(-185.29deg);
	-webkit-transform: rotate(185.29deg) translate(var(--radius)) rotate(-185.29deg);
	        transform: rotate(185.29deg) translate(var(--radius)) rotate(-185.29deg);
}

.desktop.form-field:nth-of-type(15) {
	-webkit-transform: rotate(206.47deg) translate(200vmin) rotate(-206.47deg);
	        transform: rotate(206.47deg) translate(200vmin) rotate(-206.47deg);
	-webkit-transform: rotate(206.47deg) translate(var(--radius)) rotate(-206.47deg);
	        transform: rotate(206.47deg) translate(var(--radius)) rotate(-206.47deg);
}

.desktop.form-field:nth-of-type(16) {
	-webkit-transform: rotate(227.65deg) translate(200vmin) rotate(-227.65deg);
	        transform: rotate(227.65deg) translate(200vmin) rotate(-227.65deg);
	-webkit-transform: rotate(227.65deg) translate(var(--radius)) rotate(-227.65deg);
	        transform: rotate(227.65deg) translate(var(--radius)) rotate(-227.65deg);
}

.desktop.form-field:nth-of-type(17) {
	-webkit-transform: rotate(248.82deg) translate(200vmin) rotate(-248.82deg);
	        transform: rotate(248.82deg) translate(200vmin) rotate(-248.82deg);
	-webkit-transform: rotate(248.82deg) translate(var(--radius)) rotate(-248.82deg);
	        transform: rotate(248.82deg) translate(var(--radius)) rotate(-248.82deg);
}

.form-field:nth-of-type(3n + 0) {
	background-size: 200%;
	background-position: center;
}

.form-field:nth-of-type(3n + 1) {
	background-size: 200%;
	background-position: 90% 50%;
}

.form-field:nth-of-type(3n + 2) {
	background-size: 150%;
	background-position: 50% 35%;
}


.input-container {
	display: flex;
	flex-direction: column;
	z-index: 2;
}

.form-field label {
	margin-bottom: 3px;
}

.form-field label, .form-field input, .form-field select, .form-field textarea {
	z-index: 1;
	display: block;
}

input, select, textarea, button {
	background-color: #F4F2EB;
	border: 2px solid black;
	border-radius: 8px;
	padding: 3px;
	font-family: inherit;
	font-size: inherit;
}

#race-ethnicity-field {
	color: white;
}

#race-ethnicity-field > .input-container {
	display: block;
}

#race-ethnicity-field legend {
	margin-bottom: 5px;
}

#race-ethnicity-field label {
	margin-left: 7px;
}

#race-ethnicity-field input {
	width: 15px;
	height: 15px;
}

#race-ethnicity-field label, #race-ethnicity-field input {
	display: inline;
}

.btn-back, .btn-next {
	z-index: 2;
	font-size: 1rem;
	padding: 5px;
	width: 4em;
	height: 4em;
	margin: 5px;
	cursor: pointer;
}

textarea {
	height: 15rem;
}

.desktop.decoration {
	width: 40vmin;
	position: absolute;
	top: 500vmin;
	left: 500vmin;
}

.mobile .decoration {
	display: none;
}

#flame-deco {
	top: 475vmin;
	left: 600vmin;
	width: 170vmin;
}

#party-deco {
	left: 350vmin;
	top: 552vmin;
	width: 90vmin;	
}

#bird-deco {
	top: 650vmin;
	left: 385vmin;
	width: 150vmin;
	height: 100vmin;
}

#cage-deco {
	left: 325vmin;
	top: 480vmin;
	width: 80vmin;
	background-color: #F4F2EB;
	border-radius: 40px;
}

#twisty-deco {
	left: 235vmin;
	top: 460vmin;
	width: 140vmin;
	height: 200vmin;
	rotate: -40deg;
}

#friends-deco {
	left: 385vmin;
	top: 677vmin;
	width: 45vmin;
	border-radius: 40px;
}

#cake-deco {
	left: 632vmin;
	top: 520vmin;
	width: 42vmin;
	border-radius: 40px;
}

#yeah-deco {
	left: 625vmin;
	top: 410vmin;
	width: 45vmin;
	border-radius: 40px;
}

#speech-deco {
	left: 545vmin;
	top: 615vmin;
	width: 50vmin;
	border-radius: 40px;
}

#stretched-bird-deco {
	left: 460vmin;
	top: 260vmin;
	width: 200vmin;
	height: 80vmin;
	rotate: 180deg;
	-webkit-transform: scaleX(-1);
	        transform: scaleX(-1);
}

#hug-collapse-deco {
	left: 685vmin;
	top: 532vmin;
	width: 50vmin;
}

#view-deco {
	left: 570vmin;
	top: 340vmin;
	width: 50vmin;
	border-radius: 40px;
}

#gem-deco {
	left: 585vmin;
	top: 300vmin;
	width: 170vmin;
	-webkit-transform: rotate(0deg);
	        transform: rotate(0deg);
}

#twisty-deco2 {
	left: 535vmin;
	top: 600vmin;
	width: 170vmin;
}

#group-deco {
	left: 375vmin;
	top: 320vmin;
	width: 90vmin;
	border-radius: 40px;
}

#flame-deco2 {
	left: 325vmin;
	top: 270vmin;
	width: 150vmin;
	height: 180vmin;
	rotate: 90deg;
}

#gem-deco2 {
	left: 330vmin;
	top: 600vmin;
	width: 100vmin;
}

.form-nav {
	display: flex;
}

#btn-finish {
	display: block;
	margin: 30px auto;
	width: 30vw;
	height: 30vw;
	padding: 40px 0;
	border-radius: 50%;
}

.desktop #btn-finish {
	display: none;
}

#submit-ring, #submit-btn, #filling-circle {
	position: absolute;
	top: 500vmin;
	left: 500vmin;
	border-radius: 50%;
	translate: -50% -50%;
	display: none;
}

#submit-ring, #submit-btn {
	width: calc(2 * 200vmin + 140vmin);
	width: var(--ring-width);
	height: calc(2 * 200vmin + 140vmin);
	height: var(--ring-width);
	scale: 0;
}

#submit-ring {
	background-image: url(/static/media/submit-ring.05395ac2.svg);
	background-size: contain;
}

#submit-btn {
	box-sizing: content-box;
	border: 30px solid #673ccd;
	cursor: pointer;
}

#filling-circle {
	pointer-events: none;
	background-color: #673ccd;
	opacity: .49;
	z-index: 0;
}
/* #submit-ring.visible {
	background-image: radial-gradient(#00000000 0%,  #00000000 55%, #673CCD88 55%, #673CCD11 77.5%, #673CCDFF 100%),
				url("submit-ring.svg");
	background-size: cover;
} */

#submit-ring.visible {
	display: block;
	pointer-events: none;
	-webkit-animation-name: spin-out, rotate;
	        animation-name: spin-out, rotate;
	-webkit-animation-duration: 1000ms, 105000ms;
	        animation-duration: 1000ms, 105000ms;
	-webkit-animation-iteration-count: 1, infinite;
	        animation-iteration-count: 1, infinite;
	-webkit-animation-fill-mode: forwards, none;
	        animation-fill-mode: forwards, none;
	-webkit-animation-timing-function: cubic-bezier(), linear;
	        animation-timing-function: cubic-bezier(), linear;
	-webkit-animation-delay: 0s, 1s;
	        animation-delay: 0s, 1s;
}

#submit-btn.visible {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	-webkit-animation-name: spin-out;

	        animation-name: spin-out;
	-webkit-animation-duration: 1000ms;
	        animation-duration: 1000ms;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: cubic-bezier();
	        animation-timing-function: cubic-bezier();
}

#submit-btn > h1 {
	font-size: 12rem;
}

.big-text {
	font-size: 24rem;
}

#filling-circle.visible {
	display: block;
	width: calc(calc(2 * 200vmin + 140vmin) * 0.33);
	width: calc(var(--ring-width) * 0.33);
	height: calc(calc(2 * 200vmin + 140vmin) * 0.33);
	height: calc(var(--ring-width) * 0.33);
	transition: all 0.75s ease-out;
}

#filling-circle.first {
	width: calc(calc(2 * 200vmin + 140vmin) * 0.55);
	width: calc(var(--ring-width) * 0.55);
	height: calc(calc(2 * 200vmin + 140vmin) * 0.55);
	height: calc(var(--ring-width) * 0.55);
}

#filling-circle.second {
	width: calc(calc(2 * 200vmin + 140vmin) * 0.77);
	width: calc(var(--ring-width) * 0.77);
	height: calc(calc(2 * 200vmin + 140vmin) * 0.77);
	height: calc(var(--ring-width) * 0.77);
}

#filling-circle.third {
	width: calc(calc(2 * 200vmin + 140vmin) * 1.01);
	width: calc(var(--ring-width) * 1.01);
	height: calc(calc(2 * 200vmin + 140vmin) * 1.01);
	height: calc(var(--ring-width) * 1.01);

	-webkit-animation-name: opacify;

	        animation-name: opacify;
	-webkit-animation-duration: 1000ms;
	        animation-duration: 1000ms;
	-webkit-animation-delay: 750ms;
	        animation-delay: 750ms;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

#submission-message {
	position: absolute;
	top: 500vmin;
	left: 500vmin;
	translate: -50% -50%;
	width: 60em;
	text-align: center;
	opacity: 0;

	-webkit-animation-name: opacify;

	        animation-name: opacify;
	-webkit-animation-delay: 4.75s;
	        animation-delay: 4.75s;
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

#submission-message > h1 {
	margin-bottom: 10px;
}

#back-to-app {
	position: absolute;
	top: 230vmin;
	left: 670vmin;
	width: 140vmin;
	height: 140vmin;
	border-radius: 50%;
	box-shadow: black 15px 25px 6px;
	color: white;
	font-size: 5rem;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: linear-gradient(120deg, rgba(164,131,236,1) 0%, rgba(103,60,205,1) 100%);  z-index: 99;
	/* background-color: #673ccdDD; */

	-webkit-animation-name: floating;

	        animation-name: floating;
	-webkit-animation-duration: 3s;
	        animation-duration: 3s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	-webkit-animation-timing-function: ease-in-out;
	        animation-timing-function: ease-in-out;

	transition: opacity .4s, translate .2s, box-shadow .2s;
}

#back-to-app::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: linear-gradient(180deg, rgba(164,131,236,1) 0%, rgba(103,60,205,1) 100%);  z-index: 99;
	z-index: -1;
	opacity: 0;
	transition: opacity .3s linear;
	border-radius: 50% 50%;
  }
  
#back-to-app:hover::before {
	opacity: 1;
}
  
#back-to-app:hover {
	opacity: 1;
}

#back-to-app:active {
	translate: 10px 20px;
	box-shadow: black 5px 5px 6px;
}

.errorMessage {
	color: red;
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    to {
        -webkit-transform: rotate(720deg);
                transform: rotate(720deg);
    }
}

@keyframes rotate {
    from {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    to {
        -webkit-transform: rotate(720deg);
                transform: rotate(720deg);
    }
}

@-webkit-keyframes spin-out {
	0% {
		scale: .1;
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		scale: 1;
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes spin-out {
	0% {
		scale: .1;
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		scale: 1;
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@-webkit-keyframes grow-up {
	0% {
		scale: .1;
	}
	100% {
		scale: 1;
	}
}

@keyframes grow-up {
	0% {
		scale: .1;
	}
	100% {
		scale: 1;
	}
}

@-webkit-keyframes opacify {
	100% {
		opacity: 1;
	}
}

@keyframes opacify {
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes shrink-out {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(720deg);
		        transform: rotate(720deg);
		scale: 0;
	}
}

@keyframes shrink-out {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(720deg);
		        transform: rotate(720deg);
		scale: 0;
	}
}

@-webkit-keyframes floating {
	0% {
		-webkit-transform: translate(0px, 0px);
		        transform: translate(0px, 0px);
	}
	50% {
		-webkit-transform: translate(0px, -35px);
		        transform: translate(0px, -35px);
	}
	100% {
		-webkit-transform: translate(0px, 0px);
		        transform: translate(0px, 0px);
	}
}

@keyframes floating {
	0% {
		-webkit-transform: translate(0px, 0px);
		        transform: translate(0px, 0px);
	}
	50% {
		-webkit-transform: translate(0px, -35px);
		        transform: translate(0px, -35px);
	}
	100% {
		-webkit-transform: translate(0px, 0px);
		        transform: translate(0px, 0px);
	}
}

