@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  line-height: normal;
  box-sizing: border-box;
  font-family: 'Raleway';
}

:root {
  /* fonts */
  --font-inter: Inter;
  --font-interstatemono: InterstateMono;

  /* font sizes */
  --font-size-13xl: 32px;
  --font-size-5xl: 24px;
  --font-size-31xl: 50px;
  --font-size-xl: 20px;
  --font-size-21xl: 40px;

  /* Colors */
  --color-linen: #f4f2eb;
  --color-black: #000;
  --color-blueviolet: #6734d1;
  --color-white: #fff;

  /* border radiuses */
  --br-11xl: 30px;
}

:root {
	--radius: 200vmin;
	--ring-width: calc(2 * var(--radius) + 140vmin);
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

h1, h2 {
	font-weight: normal;
}

.desktop.CohortApplication > * {
	transform-origin: 500vmin 500vmin;
}

.desktop.CohortApplication {
	width: 1000vmin;
	height: 1000vmin;
}

.CohortApplication {
	background-color: #F4F2EB;
}

form {
	transition: scale .5s ease-out;
}

form.submitted {
	animation-name: shrink-out;
	animation-delay: 2.75s;
	animation-duration: 1.75s;
	animation-timing-function: cubic-bezier(1,.06,.86,.31);
	animation-fill-mode: forwards;
}

.form-field {
	display: flex;
	flex-direction: column;
	justify-content: center;
	
	color: white;
	color: #F4F2EB;
	border: 10px solid #321d60;
	background: linear-gradient(#41218670 0%, #41218670 100%), url("img/twisty-blob.png");
}

.mobile.form-field {
	padding: 40px 30px;
	border-width: 2px;
	border-radius: 10px;
	margin: 5px;
}

.desktop.form-field {
	position: absolute;
	border-radius: 50%;
	width: 50vmin;
	height: 50vmin;
	transform-origin: 50% 50%;
	min-width: 30em;
	min-height: 30em;
	padding: 6em;
	top: 500vmin;
	left: 500vmin;
	translate: -50% -50%;
	align-items: center;
	font-size: 1.25rem;
}

.mobile.form-field .form-nav {
	display: none;
}

.desktop.form-field:nth-of-type(1) {
	transform: rotate(-90.0deg) translate(var(--radius)) rotate(90.0deg);
}

.desktop.form-field:nth-of-type(2) {
	transform: rotate(-68.82deg) translate(var(--radius)) rotate(68.82deg);
}

.desktop.form-field:nth-of-type(3) {
	transform: rotate(-47.65deg) translate(var(--radius)) rotate(47.65deg);
}

.desktop.form-field:nth-of-type(4) {
	transform: rotate(-26.47deg) translate(var(--radius)) rotate(26.47deg);
}

.desktop.form-field:nth-of-type(5) {
	transform: rotate(-5.29deg) translate(var(--radius)) rotate(5.29deg);
}

.desktop.form-field:nth-of-type(6) {
	transform: rotate(15.88deg) translate(var(--radius)) rotate(-15.88deg);
}

.desktop.form-field:nth-of-type(7) {
	transform: rotate(37.06deg) translate(var(--radius)) rotate(-37.06deg);
}

.desktop.form-field:nth-of-type(8) {
	transform: rotate(58.24deg) translate(var(--radius)) rotate(-58.24deg);
}

.desktop.form-field:nth-of-type(9) {
	transform: rotate(79.41deg) translate(var(--radius)) rotate(-79.41deg);
}

.desktop.form-field:nth-of-type(10) {
	transform: rotate(100.59deg) translate(var(--radius)) rotate(-100.59deg);
}

.desktop.form-field:nth-of-type(11) {
	transform: rotate(121.76deg) translate(var(--radius)) rotate(-121.76deg);
}

.desktop.form-field:nth-of-type(12) {
	transform: rotate(142.94deg) translate(var(--radius)) rotate(-142.94deg);
}

.desktop.form-field:nth-of-type(13) {
	transform: rotate(164.12deg) translate(var(--radius)) rotate(-164.12deg);
}

.desktop.form-field:nth-of-type(14) {
	transform: rotate(185.29deg) translate(var(--radius)) rotate(-185.29deg);
}

.desktop.form-field:nth-of-type(15) {
	transform: rotate(206.47deg) translate(var(--radius)) rotate(-206.47deg);
}

.desktop.form-field:nth-of-type(16) {
	transform: rotate(227.65deg) translate(var(--radius)) rotate(-227.65deg);
}

.desktop.form-field:nth-of-type(17) {
	transform: rotate(248.82deg) translate(var(--radius)) rotate(-248.82deg);
}

.form-field:nth-of-type(3n + 0) {
	background-size: 200%;
	background-position: center;
}

.form-field:nth-of-type(3n + 1) {
	background-size: 200%;
	background-position: 90% 50%;
}

.form-field:nth-of-type(3n + 2) {
	background-size: 150%;
	background-position: 50% 35%;
}


.input-container {
	display: flex;
	flex-direction: column;
	z-index: 2;
}

.form-field label {
	margin-bottom: 3px;
}

.form-field label, .form-field input, .form-field select, .form-field textarea {
	z-index: 1;
	display: block;
}

input, select, textarea, button {
	background-color: #F4F2EB;
	border: 2px solid black;
	border-radius: 8px;
	padding: 3px;
	font-family: inherit;
	font-size: inherit;
}

#race-ethnicity-field {
	color: white;
}

#race-ethnicity-field > .input-container {
	display: block;
}

#race-ethnicity-field legend {
	margin-bottom: 5px;
}

#race-ethnicity-field label {
	margin-left: 7px;
}

#race-ethnicity-field input {
	width: 15px;
	height: 15px;
}

#race-ethnicity-field label, #race-ethnicity-field input {
	display: inline;
}

.btn-back, .btn-next {
	z-index: 2;
	font-size: 1rem;
	padding: 5px;
	width: 4em;
	height: 4em;
	margin: 5px;
	cursor: pointer;
}

textarea {
	height: 15rem;
}

.desktop.decoration {
	width: 40vmin;
	position: absolute;
	top: 500vmin;
	left: 500vmin;
}

.mobile .decoration {
	display: none;
}

#flame-deco {
	top: 475vmin;
	left: 600vmin;
	width: 170vmin;
}

#party-deco {
	left: 350vmin;
	top: 552vmin;
	width: 90vmin;	
}

#bird-deco {
	top: 650vmin;
	left: 385vmin;
	width: 150vmin;
	height: 100vmin;
}

#cage-deco {
	left: 325vmin;
	top: 480vmin;
	width: 80vmin;
	background-color: #F4F2EB;
	border-radius: 40px;
}

#twisty-deco {
	left: 235vmin;
	top: 460vmin;
	width: 140vmin;
	height: 200vmin;
	rotate: -40deg;
}

#friends-deco {
	left: 385vmin;
	top: 677vmin;
	width: 45vmin;
	border-radius: 40px;
}

#cake-deco {
	left: 632vmin;
	top: 520vmin;
	width: 42vmin;
	border-radius: 40px;
}

#yeah-deco {
	left: 625vmin;
	top: 410vmin;
	width: 45vmin;
	border-radius: 40px;
}

#speech-deco {
	left: 545vmin;
	top: 615vmin;
	width: 50vmin;
	border-radius: 40px;
}

#stretched-bird-deco {
	left: 460vmin;
	top: 260vmin;
	width: 200vmin;
	height: 80vmin;
	rotate: 180deg;
	transform: scaleX(-1);
}

#hug-collapse-deco {
	left: 685vmin;
	top: 532vmin;
	width: 50vmin;
}

#view-deco {
	left: 570vmin;
	top: 340vmin;
	width: 50vmin;
	border-radius: 40px;
}

#gem-deco {
	left: 585vmin;
	top: 300vmin;
	width: 170vmin;
	transform: rotate(0deg);
}

#twisty-deco2 {
	left: 535vmin;
	top: 600vmin;
	width: 170vmin;
}

#group-deco {
	left: 375vmin;
	top: 320vmin;
	width: 90vmin;
	border-radius: 40px;
}

#flame-deco2 {
	left: 325vmin;
	top: 270vmin;
	width: 150vmin;
	height: 180vmin;
	rotate: 90deg;
}

#gem-deco2 {
	left: 330vmin;
	top: 600vmin;
	width: 100vmin;
}

.form-nav {
	display: flex;
}

#btn-finish {
	display: block;
	margin: 30px auto;
	width: 30vw;
	height: 30vw;
	padding: 40px 0;
	border-radius: 50%;
}

.desktop #btn-finish {
	display: none;
}

#submit-ring, #submit-btn, #filling-circle {
	position: absolute;
	top: 500vmin;
	left: 500vmin;
	border-radius: 50%;
	translate: -50% -50%;
	display: none;
}

#submit-ring, #submit-btn {
	width: var(--ring-width);
	height: var(--ring-width);
	scale: 0;
}

#submit-ring {
	background-image: url("img/submit-ring.svg");
	background-size: contain;
}

#submit-btn {
	box-sizing: content-box;
	border: 30px solid #673ccd;
	cursor: pointer;
}

#filling-circle {
	pointer-events: none;
	background-color: #673ccd;
	opacity: .49;
	z-index: 0;
}
/* #submit-ring.visible {
	background-image: radial-gradient(#00000000 0%,  #00000000 55%, #673CCD88 55%, #673CCD11 77.5%, #673CCDFF 100%),
				url("submit-ring.svg");
	background-size: cover;
} */

#submit-ring.visible {
	display: block;
	pointer-events: none;
	animation-name: spin-out, rotate;
	animation-duration: 1000ms, 105000ms;
	animation-iteration-count: 1, infinite;
	animation-fill-mode: forwards, none;
	animation-timing-function: cubic-bezier(), linear;
	animation-delay: 0s, 1s;
}

#submit-btn.visible {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	animation-name: spin-out;
	animation-duration: 1000ms;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-timing-function: cubic-bezier();
}

#submit-btn > h1 {
	font-size: 12rem;
}

.big-text {
	font-size: 24rem;
}

#filling-circle.visible {
	display: block;
	width: calc(var(--ring-width) * 0.33);
	height: calc(var(--ring-width) * 0.33);
	transition: all 0.75s ease-out;
}

#filling-circle.first {
	width: calc(var(--ring-width) * 0.55);
	height: calc(var(--ring-width) * 0.55);
}

#filling-circle.second {
	width: calc(var(--ring-width) * 0.77);
	height: calc(var(--ring-width) * 0.77);
}

#filling-circle.third {
	width: calc(var(--ring-width) * 1.01);
	height: calc(var(--ring-width) * 1.01);

	animation-name: opacify;
	animation-duration: 1000ms;
	animation-delay: 750ms;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

#submission-message {
	position: absolute;
	top: 500vmin;
	left: 500vmin;
	translate: -50% -50%;
	width: 60em;
	text-align: center;
	opacity: 0;

	animation-name: opacify;
	animation-delay: 4.75s;
	animation-duration: 1s;
	animation-fill-mode: forwards;
}

#submission-message > h1 {
	margin-bottom: 10px;
}

#back-to-app {
	position: absolute;
	top: 230vmin;
	left: 670vmin;
	width: 140vmin;
	height: 140vmin;
	border-radius: 50%;
	box-shadow: black 15px 25px 6px;
	color: white;
	font-size: 5rem;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: linear-gradient(120deg, rgba(164,131,236,1) 0%, rgba(103,60,205,1) 100%);  z-index: 99;
	/* background-color: #673ccdDD; */

	animation-name: floating;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;

	transition: opacity .4s, translate .2s, box-shadow .2s;
}

#back-to-app::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: linear-gradient(180deg, rgba(164,131,236,1) 0%, rgba(103,60,205,1) 100%);  z-index: 99;
	z-index: -1;
	opacity: 0;
	transition: opacity .3s linear;
	border-radius: 50% 50%;
  }
  
#back-to-app:hover::before {
	opacity: 1;
}
  
#back-to-app:hover {
	opacity: 1;
}

#back-to-app:active {
	translate: 10px 20px;
	box-shadow: black 5px 5px 6px;
}

.errorMessage {
	color: red;
}

@keyframes rotate {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(720deg);
    }
}

@keyframes spin-out {
	0% {
		scale: .1;
		transform: rotate(0deg);
	}
	100% {
		scale: 1;
		transform: rotate(360deg);
	}
}

@keyframes grow-up {
	0% {
		scale: .1;
	}
	100% {
		scale: 1;
	}
}

@keyframes opacify {
	100% {
		opacity: 1;
	}
}

@keyframes shrink-out {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(720deg);
		scale: 0;
	}
}

@keyframes floating {
	0% {
		transform: translate(0px, 0px);
	}
	50% {
		transform: translate(0px, -35px);
	}
	100% {
		transform: translate(0px, 0px);
	}
}
