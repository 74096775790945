.testphoto31Icon {
  position: absolute;
  top: 4473px;
  left: 675px;
  border-radius: 48px;
  width: 434px;
  height: 334px;
  object-fit: cover;
  display: none;
}
.hover {
  /* display: flex; */
  position: relative;
}
.overlay1,
.overlay2,
.overlay3,
.overlay4,
.overlay5,
.overlay6,
.overlay7,
.overlay8 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: var(--br-11xl);
  opacity: 0;
}
.scopegradientorb2Icon {
  position: absolute;
  /* width: 81.14%; */
  visibility: 'inherit';
  overflow-x: hidden;
  top: 2402px;
  right: 0;
  left: 30%;
  max-width: 100%;
  overflow: visible;
  height: 2611.83px;
  object-fit: cover;
}
.testphoto21Icon {
  position: absolute;
  top: 4408px;
  left: 1072px;
  width: 447px;
  height: 427px;
  object-fit: cover;
  display: none;
}
.globewShadow1Icon,
.scopegradientorb1Icon {
  position: absolute;
  width: 79.57%;
  top: 4817px;
  right: 20.43%;
  left: 0;
  max-width: 100%;
  overflow: visible;
  border: 0;
  height: 2447.04px;
  object-fit: cover;
}
.scopegradientorb1Icon {
  /* width: %; */
  top: 0;
  left: 0%;
  /* transition: all .3s ease-in-out; */
  right: 39.56%;
  height: 1273.47px;
  border: 0;
}
.globewShadow1Icon {
    position: absolute;
  width: 79.57%;
  top: 4500px;
  right: 20.43%;
  left: 0;
  max-width: 100%;
  overflow: visible;
  height: 2447.04px;
  object-fit: cover;
}
.applicationsForThe {
  margin: 0;
  font-size: 2.2rem;
  font-weight: bold;
  width: 200%;
}
.applicationsForTheSwift {
  margin: 0;

}
.applicationsForTheContainer {
  position: absolute;
  top: 7.34%;
  left: 11.09%;
  font-size: var(--font-size-5xl);
  font-weight: 300;
  color: var(--color-white);
  text-align: left;
  display: inline-block;
  width: 685px;
  height: 135px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  /* -webkit-text-stroke: 1px #000; */
}
.rectangleIcon3 {
  position: absolute;
  height: 79.89%;
  width: 43.11%;
  top: 0.34%;
  right: 38.6%;
  bottom: 19.77%;
  left: 18.29%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.rectangleIcon,
.rectangleIcon1 {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.rectangleIcon {
  position: absolute;
  height: 75.83%;
  width: 42.06%;
  top: 0.34%;
  right: 39.13%;
  bottom: 23.83%;
  left: 18.81%;
  overflow: hidden;
}
.rectangleIcon1 {
  height: 73.22%;
  width: 16.07%;
  top: 0;
  right: 83.93%;
  bottom: 26.78%;
  left: 0;
}
.mainlogo,
.rectangleIcon1,
.rectangleIcon2 {
  position: absolute;
  overflow: hidden;
}
.rectangleIcon2 {
  height: 98.14%;
  width: 36.14%;
  top: 2.17%;
  right: -0.08%;
  bottom: -0.31%;
  left: 63.95%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.mainlogo {
  top: 319px;
  left: 200px;
  width: 821px;
  height: 212px;
  display: none;
  background-image: url('/whiteScope.png');
}
.mainlogo:hover {
  background-image: url('/purpleScope.png');
}
.top {
  position: absolute;
  top: 514px;
  left: 11%;
  font-size: var(--font-size-21xl);
  font-weight: 600;
  color: var(--color-white);
  text-align: left;
  display: inline-block;
  width: 579px;
  height: 74px;
}
.learnBuildCode {
  position: absolute;
  top: 6.4%;
  left: 11%;
  font-size: var(--font-size-21xl);
  font-weight: 600;
  color: var(--color-white);
  text-align: left;
  display: inline-block;
  width: 620px;
  height: 74px;
}
.top {
  top: 19px;
  left: 212px;
  color: var(--color-linen);
}
.applyNow {
  position: absolute;
  top: 9.3%;
  font-size: 30px;
  padding: 2rem 20rem;
  font-family: 'Raleway';
  border-radius: 25px;
  background: #f4f1de;
  /* background: linear-gradient(180deg, rgba(164,131,236,1) 0%, rgba(103,60,205,1) 100%);  z-index: 99; */
  color: #5b04f6;
  left: 11%;
  font-size: 2.3rem;
  z-index: 1;
  font-weight: bold;
  box-shadow: rgb(5, 18, 64) 5px 5px;
  transition: box-shadow .2s linear, translate .2s linear;
}

.applyNow::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(30deg, rgba(164,131,236,1) 0%, rgba(103,60,205,1) 100%);  z-index: 99;
  z-index: -1;
  opacity: 0;
  transition: opacity .3s linear;
  border-radius: 25px;
}

.applyNow:hover::before {
  opacity: 1;
}

.applyNow:hover {
  background-color: #5b04f6;
  color: #F4F2EB;
  cursor: pointer;
  /* background-color: white; */
  z-index: 99;
  left: 11%;
  box-shadow: rgb(5, 18, 64) 10px 10px;
  translate: -5px -5px; 
}

.applyNow:active {
  box-shadow: rgb(5, 18, 64) 0px 0px;
  translate: 0px 0px; 
}

.hackathonPhotoIcon,
.paulphotoIcon {
  position: absolute;
  top: 1821px;
  left: -7%;
  width: 831px;
  height: 690px;
  object-fit: cover;
}
.paulphotoIcon {
  top: 1081px;
  left: -15%;
  width: 660.97px;
  height: 1136.97px;
}
.eachSemesterWe {
  position: absolute;
  top: 4368px;
  left: calc(50% - 414.5px);
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  display: inline-block;
  width: 827px;
  height: 503px;
}
.buildAndLearn,
.thatsTheMottoContainer {
  position: relative;
  display: inline-block;
  width: 904px;
  height: 85px;
  flex-shrink: 0;
}
.thatsTheMottoContainer {
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  width: 900px;
  height: 503px;
}
.mottoFrame {
  position: absolute;
  top: 1467px;
  left: calc(50% - 451.5px);
  width: 905px;
  height: 608px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 20px;
  font-size: var(--font-size-21xl);
}
.about,
.ourCurriculum {
  display: inline-block;
  height: 146px;
}
.about {
  position: absolute;
  top: 1237px;
  left: 472px;
  font-size: var(--font-size-21xl);
  font-family: 'Raleway';
  color: var(--color-linen);
  width: 826px;
}
.ourCurriculum {
  top: 2953px;
  left: calc(50% - 473.5px);
}
.learn,
.ourCurriculum,
.theCohort {
  position: absolute;
  width: 949px;
}
.learn {
  top: 2787px;
  left: 389px;
  display: inline-block;
  font-family: 'Raleway';
  color: var(--color-linen);
  height: 146px;
}
.theCohort {
  top: 4284px;
  left: calc(50% - 475.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
}
.theCountdown {
  top: 11%;
  left: 40px;
  /* display: flex; */
  font-weight: 800;
  color: var(--color-linen);
  font-family: 'Raleway';
  align-items: center;
  /* justify-content: center; */
  height: 74px;
}
.cohort,
.frequentlyAskedQuestions {
  position: absolute;
  display: inline-block;
  width: 949px;
  height: 146px;
}
.cohort {
  top: 4250px;
  display: inline-block;
  left: 300px;
  font-family: 'Raleway';
  color: var(--color-linen);
}
.frequentlyAskedQuestions {
  top: 5175px;
  left: calc(50% - 473.5px);
}
.theBoard {
  top: 6723px;
  left: calc(50% - 469.5px);
  width: 949px;
  height: 146px;
}
.atScopeWe,
.faq,
.theBoard {
  position: absolute;
  display: inline-block;
}
.faq {
  top: 4999px;
  left: 405px;
  font-family: 'Raleway';
  color: var(--color-linen);
  width: 949px;
  height: 146px;
}
.atScopeWe {
  top: 3047px;
  left: calc(50% - 497.5px);
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  width: 997px;
  height: 337px;
}
.jakepointingIcon {
  position: absolute;
  top: 1443px;
  right: -13%;
  /* width: 383px; */
  height: 1038.45px;
  object-fit: cover;
}
.facebookButtonIcon,
.githubButtonIcon,
.instagramButtonIcon {
  position: absolute;
  top: 456px;
  right: 71px;
  width: 60px;
  height: 58px;
  object-fit: cover;
  cursor: pointer;
}
.facebookButtonIcon,
.instagramButtonIcon {
  top: 458px;
  right: 147px;
  width: 54px;
  height: 54px;
}
.instagramButtonIcon {
  right: 1px;
  height: 56px;
}
.about1,
.cohort1,
.faq1,
.learn1,
.scopeCup,
.sponsor {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 201px;
  height: 65px;
  cursor: pointer;
}
.cohort1,
.faq1,
.learn1,
.scopeCup,
.sponsor {
  top: 380px;
}
.cohort1,
.faq1,
.learn1,
.sponsor {
  top: 304px;
}
.cohort1,
.faq1,
.learn1 {
  top: 228px;
}
.cohort1,
.learn1 {
  top: 152px;
}
.learn1 {
  top: 76px;
}
.about2,
.cohort2,
.faq2,
.learn2,
.linkStack,
.scopeCup1,
.sponsor1 {
  position: absolute;
  font-size: var(--font-size-13xl);
  text-align: right;
}
.linkStack {
  top: 355px;
  right: 159px;
  width: 223px;
  height: 514px;
}
.about2,
.cohort2,
.faq2,
.learn2,
.scopeCup1,
.sponsor1 {
  left: 1377px;
  display: none;
  width: 201px;
  height: 65px;
}
.about2 {
  top: 324px;
  cursor: pointer;
}
.cohort2,
.faq2,
.learn2,
.scopeCup1,
.sponsor1 {
  top: 704px;
}
.cohort2,
.faq2,
.learn2,
.sponsor1 {
  top: 628px;
}
.cohort2,
.faq2,
.learn2 {
  top: 552px;
  cursor: pointer;
}
.cohort2,
.learn2 {
  top: 476px;
}
.learn2 {
  top: 400px;
}

.scopeOffWhite3d2 {
  position: absolute;
  top: 0;
  right: 0;

  /* border: 1px solid var(--color-black); */
  box-sizing: border-box;
  width: 761px;
  height: 197px;
  overflow: hidden;
}

.scopeLogo {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  position: absolute;
  top: 3.5%;
  left: 10%;
  width: 780px;
  height: 230px;
  visibility: 'inherit';
  background-image: url('/whiteScope.png');
  background-repeat: no-repeat;
  background-size: 780px;
  transition: all .6s ease-in-out;
  /* transition: height .7s ease-in; */
}
.scopeLogo:hover {
  width: 795px;
  cursor: pointer;
  filter: drop-shadow(-5px -5px 10px #E1E1E1);
  background-size: 795px;
  background-image: url('/purpleScope.png');
  transition: all .6s ease-in-out;
}
@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 20px); }
  100%   { transform: translate(0, -0px); }   
}
.belongSymbolIcon,
.bondSymbolIcon,
.learnSymbolIcon {
  position: absolute;
  top: 8px;
  left: calc(50% - 451.5px);
  width: 128px;
  height: 105px;
  object-fit: cover;
}
.belongSymbolIcon,
.bondSymbolIcon {
  top: 0;
  left: calc(50% - 45.5px);
  width: 98px;
  height: 130px;
}
.belongSymbolIcon {
  left: calc(50% + 318.5px);
  width: 142px;
  height: 144px;
}

.name {
  opacity: 1;
  font-size: 1.8rem;
  font-family: 'Raleway';
  font-weight: 510;
  position: absolute;
  color: #5b04f6;
  text-align: center;
  z-index: 99;
  top: 85%;
  left: 50%;
  /* -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  text-align: center;
}
  .role {
    font-size: 1rem;
    font-family: 'Raleway';
    text-transform: uppercase;
    font-weight: 700;
    color: black; 
    white-space: nowrap;
  }
  .hover:hover {
    cursor: pointer;
    .img {
      opacity: .8;
    }
    .eboardImg1,
    .eboardImg2,
    .eboardImg3,
    .eboardImg4,
    .eboardImg5,
    .eboardImg6,
    .eboardImg7,
    .eboardImg8 {
      opacity: .6;
      border-radius: 75px;
    }
    .overlay1 {
      opacity: 1;
      top: 70px;
      left: -44%;
    }
    .overlay2 {
      opacity: 1;
      top: 70px;
      left: -17.5%;
    }
    .overlay3 {
      opacity: 1;
      top: 70px;
      left: 6%;
    }
    .overlay4 {
      opacity: 1;
      top: 70px;
      left: 34%;
    }
    .overlay5 {
      opacity: 1;
      top: 280px;
      left: -45%;
    }
    .overlay6 {
      opacity: 1;
      top: 280px;
      left: -18.5%;
    }
    .overlay7 {
      opacity: 1;
      top: 280px;
      left: 8.5%;
    }
    .overlay8 {
      opacity: 1;
      top: 280px;
      left: 33.5%;
    }
  
  }
  .img {
    display: flex;
    flex-direction: column;
    max-width: 100px;
    max-height:100px;
    align-items: center;
    color: white;
    text-align: center;
  }
  .eboardImg8 {
    position: absolute;
    top: 216px;
    left: 78%;
    width: 193px;
    height: 192px;
    /* object-fit: cover; */
  }
  .eboardImg7 {
    position: absolute;
    top: 216px;
    left: 52.3%;
    width: 193px;
    height: 192px;
    /* object-fit: cover; */
  }
  .eboardImg6 {
    position: absolute;
    top: 216px;
    left: 26.2%;
    width: 193px;
    height: 192px;
    /* object-fit: cover; */
  }
  .eboardImg5 {
    position: absolute;
    top: 216px;
    width: 193px;
    height: 192px;
    /* object-fit: cover; */
  }
  .eboardImg4 {
    position: absolute;
    top: 0%;
    left: 78%;
    width: 193px;
    height: 192px;
    /* object-fit: cover; */
  }
  .eboardImg3 {
    position: absolute;
    top: 0%;
    left: 52.3%;
    width: 193px;
    height: 192px;
    /* object-fit: cover; */
  }
  .eboardImg2 {
    position: absolute;
    top: 0%;
    left: 26.2%;
    width: 193px;
    height: 192px;
    /* object-fit: cover; */
  }
  .eboardImg1 {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 193px;
    height: 192px;
    border-radius: 75px;
    /* object-fit: cover; */
  }
.belong,
.collaborate,
.learn3,
.learnACuttingEdge {
  position: absolute;
  top: 202px;
  left: calc(50% - 556.5px);
  display: inline-block;
  width: 331px;
  height: 447px;
}
.belong,
.collaborate,
.learn3 {
  top: 149px;
  left: calc(50% - 553.5px);
  font-size: var(--font-size-13xl);
  font-weight: 800;
  color: var(--color-blueviolet);
  height: 35px;
}
.belong,
.collaborate {
  top: 153px;
  left: calc(50% - 158.5px);
}
.belong {
  left: calc(50% + 225.5px);
  height: 31px;
}
.joinATightKnit,
.teamUpIdeate {
  position: absolute;
  top: 202px;
  left: calc(50% - 165.5px);
  display: inline-block;
  width: 337px;
  height: 447px;
}
.joinATightKnit {
  left: calc(50% + 219.5px);
}
.pillarsFrame {
  position: absolute;
  top: 2498px;
  left: calc(50% - 555.5px);
  width: 1113px;
  height: 334px;
  font-size: var(--font-size-xl);
  color: var(--color-black);
}
.arrow1Icon {
  position: absolute;
  top: 1919px;
  left: 609px;
  width: 308.84px;
  height: 331.06px;
  object-fit: cover;
  opacity: 0.8;
}
.reactNativeSpringContainer,
.theAlumni {
  position: absolute;
  top: 7614px;
  left: calc(50% - 486.5px);
  display: inline-block;
  width: 949px;
  height: 146px;
  z-index: 99;
}
.reactNativeSpringContainer {
  height: 73.49%;
  top: 13.27%;
  left: calc(50% - 47.5px);
  width: 225px;
}
.nextlogoIcon {
  position: absolute;
  top: calc(50% - 43px);
  left: 7px;
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.reactlogo1Icon {
  position: absolute;
  top: calc(50% - 43px);
  left: -9px;
  width: 98px;
  height: 98px;
  object-fit: contain;
}
.reactNativeSpring2022 {
  position: relative;
  width: 335px;
  height: 98px;
}
.reactJsContainer {
  position: absolute;
  height: 74.39%;
  top: 13.06%;
  left: calc(50% - 139px);
  display: inline-block;
  width: 408px;
}
.reactlogo1Icon1 {
  position: absolute;
  top: calc(50% - 43.2px);
  left: -9px;
  width: 98px;
  height: 87px;
  object-fit: contain;
}
.reactJsAndTypescript {
  position: relative;
  width: 518px;
  height: 98px;
}
.fall2020Virtual {
  margin: 0;
  font-size: var(--font-size-5xl);
}
.extensionsFirebaseContainer {
  position: absolute;
  height: 65.22%;
  top: 16.94%;
  left: calc(50% - 133.5px);
  display: inline-block;
  width: 397px;
}
.chromelogo1Icon {
  position: absolute;
  height: 100%;
  top: -0.41%;
  bottom: 0.41%;
  left: calc(50% - 262.5px);
  max-height: 100%;
  width: 98px;
  object-fit: cover;
}
.extensionsAndFirebase {
  position: relative;
  width: 507px;
  height: 98px;
}
.reactJsFallContainer {
  position: absolute;
  height: 71.08%;
  top: 15.71%;
  left: calc(50% - 47.5px);
  display: inline-block;
  width: 163px;
}
.reactlogo1Icon2 {
  position: absolute;
  top: calc(50% - 42.6px);
  left: -9px;
  width: 98px;
  height: 87px;
  object-fit: contain;
}
.electronFall2018Container {
  position: absolute;
  height: 64.52%;
  top: 22.65%;
  left: calc(50% - 46px);
  display: inline-block;
  width: 225px;
}
.electronlogo1Icon {
  position: absolute;
  height: 97px;
  top: 0.2%;
  bottom: -9.39%;
  left: calc(50% - 175px);
  /* max-height: 100%; */
  width: 99px;
  object-fit: contain;
}
.electron {
  position: relative;
  width: 332px;
  height: 98px;
}
.reactNativeFallContainer {
  position: absolute;
  height: 73.17%;
  top: 14.29%;
  left: calc(50% - 47.5px);
  display: inline-block;
  width: 225px;
}
.leftCurriculumColumn {
  width: 518px;
  height: 859px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.tbdFall2023Container {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 48px);
  display: inline-block;
  width: 224px;
  height: 61px;
}
.reactJsSpring2023Container {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 48px);
  display: inline-block;
  width: 224px;
  height: 61px;
}
.reactNativeSpring2022Container {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 48px);
  display: inline-block;
  width: 224px;
  height: 61px;
}
.shadowwhitequestionmark1Icon {
  position: absolute;
  width: 100%;
  top: 10px;
  right: 10px;
  left: -7px;
  max-width: 100%;
  overflow: visible;
  height: 90px;
  object-fit: cover;
}
.questionmark {
  position: absolute;
  top: calc(50% - 58px);
  left: calc(50% - 159px);
  width: 85px;
  height: 115px;
}
.tbdFall2023 {
  position: relative;
  width: 336px;
  height: 98px;
}
.reactJsAndFirebase {
  position: relative;
  width: 336px;
  height: 98px;
}
.nodejslogo1Icon {
  position: relative;
  width: 83px;
  height: 98px;
  object-fit: cover;
}
.nodeJsFallContainer {
  position: relative;
  display: inline-block;
  width: 225px;
  height: 61px;
  flex-shrink: 0;
}
.nodeJsFall2022 {
  width: 340px;
  height: 98px;
  display: flex;
  flex-direction: row;
  padding: 0 0 0.199951171875px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 37px;
}
.reactfalllogo1Icon {
  position: relative;
  padding-right: 9px;
  width: 98px;
  height: 98px;
  object-fit: contain;
}
.swiftlogo1Icon {
  position: relative;
  margin-top: 20px;
  width: 88px;
  height: 88px;
  object-fit: cover;
}
.swiftSpring2021Container {
  position: relative;
  display: inline-block;
  width: 303px;
  height: 60px;
  flex-shrink: 0;
}
.swiftSpring2021 {
  width: 423px;
  height: 98px;
  display: flex;
  flex-direction: row;
  padding: 0 0 10.39990234375px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
}
.electronlogo2Icon {
  position: relative;
  width: 86px;
  height: 94px;
  object-fit: cover;
}
.electronSpring2020Container {
  position: relative;
  display: inline-block;
  width: 224px;
  height: 59px;
  flex-shrink: 0;
}
.electronSpring2020 {
  width: 332px;
  height: 98px;
  display: flex;
  flex-direction: row;
  padding: 0.39990234375px 0 3.60009765625px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 34px;
}
.flutterlogo1Icon {
  position: relative;
  width: 72px;
  height: 87px;
  object-fit: cover;
}
.flutterSpring2019Container {
  position: relative;
  display: inline-block;
  width: 224px;
  height: 61px;
  flex-shrink: 0;
}
.flutterSpring2019 {
  width: 332px;
  height: 98px;
  display: flex;
  flex-direction: row;
  padding: 5.199951171875px 0 5.800048828125px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 48px;
}
.nodeJsBackendContainer {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 81px);
  display: inline-block;
  width: 419px;
  height: 61px;
}
.nodejslogo2Icon {
  position: absolute;
  top: calc(50% - 49px);
  left: calc(50% - 201px);
  width: 83px;
  height: 98px;
  object-fit: cover;
}
.nodejsBackendDevopsSpring {
  position: relative;
  width: 402px;
  height: 98px;
}
.curriculum,
.rightCurriculumColumn {
  height: 859px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.curriculum {
  position: absolute;
  top: 3285px;
  left: calc(50% - 518.5px);
  width: 1038px;
  height: 858px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 97px;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
}
.menuBarEnter {
  position: absolute;
  top: 1340px;
  left: 0;
  background-color: var(--color-linen);
  width: 1727px;
  height: 112px;
}
.scopeLooksFor,
.weAreAn {
  position: absolute;
  display: inline-block;
}
.weAreAn {
  width: 42.17%;
  top: 146px;
  left: 0.21%;
  color: var(--color-linen);
  height: 213px;
}
.scopeLooksFor {
  width: 43.57%;
  top: 120px;
  left: 50.63%;
  height: 134px;
}
.applicationAtContainer,
.around30,
.scopeIsTo,
.sinceOurCurriculum,
.weHaveTwo {
  position: absolute;
  width: 43.57%;
  top: 782px;
  left: 50.49%;
  display: inline-block;
  height: 193px;
}
.applicationAtContainer,
.around30,
.sinceOurCurriculum,
.weHaveTwo {
  top: 1055px;
}
.applicationAtContainer,
.around30,
.weHaveTwo {
  top: 345px;
  left: 50.63%;
  height: 380px;
}
.around30,
.weHaveTwo {
  width: 49.37%;
  top: 525px;
  left: 0.14%;
  color: var(--color-linen);
  height: 42px;
}
.weHaveTwo {
  width: 42.17%;
  top: 645px;
  height: 144px;
}
.mission {
  width: 19.72%;
  top: 1px;
  left: 0.14%;
  font-size: var(--font-size-13xl);
  color: var(--color-blueviolet);
}
.mission,
.qDoI,
.qWhatDoes,
.qWhatDoes1,
.qWhatMakes,
.qWhoIs {
  position: absolute;
  display: inline-block;
  height: 60px;
}
.qWhatMakes {
  width: 46.36%;
  top: 60px;
  left: 0.14%;
  color: var(--color-linen);
}
.qDoI,
.qWhatDoes,
.qWhatDoes1,
.qWhoIs {
  width: 49.37%;
  top: 63px;
  left: 50.63%;
}
.qDoI,
.qWhatDoes1,
.qWhoIs {
  top: 725px;
  left: 50.49%;
}
.qDoI,
.qWhatDoes1 {
  top: 998px;
}
.qWhatDoes1 {
  top: 288px;
  left: 50.63%;
}
.applicationProcess,
.involvement,
.qHowMany,
.qWhatIs {
  position: absolute;
  width: 44.76%;
  top: 442px;
  left: 0.14%;
  display: inline-block;
  color: var(--color-linen);
  height: 60px;
}
.applicationProcess,
.involvement,
.qWhatIs {
  width: 49.37%;
  top: 589px;
  left: 0;
}
.applicationProcess,
.involvement {
  width: 24.55%;
  top: 0;
  left: 50.63%;
  font-size: var(--font-size-13xl);
  color: var(--color-blueviolet);
}
.involvement {
  top: 382px;
  left: 0.14%;
}
.faq3,
.ourNetworkOf {
  position: absolute;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
}
.ourCopyright {
  position: absolute;
  left: 48.6%;
  height: 15px;
  bottom: 0.15%;
  font-size: var(--font-size-5xl);
  color: var(--color-white);
  z-index: 100;
}
.ourCopyright2 {
  position: absolute;
  left: 42.6%;
  bottom: 0.15%;
  font-weight: 300;
  color: black;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  /* color: #5b04f6; */
  z-index: 100;
}
.faq3 {
  top: 5328px;
  left: calc(50% - 770.5px);
  width: 1541px;
  height: 1248px;
  text-align: left;
}
.ourNetworkOf {
  top: 7692px;
  left: calc(50% - 436.5px);
  display: inline-block;
  width: 849px;
  z-index: 99;
  height: 67px;
}
.bottomDiv {
  overflow: hidden;
  top: 91%;
  max-height: 9%;
  /* position: relative; */
}
.bottomGraphicIcon {
  position: absolute;
  width: 80%;
  /* height: 450px; */
  bottom: -10%;
  right: 9.64%;
  left: 9.57%;
  border-radius: 420px;
  max-width: 100%;
  /* overflow- hidden; */
  /* height: 282.48px; */
  object-fit: cover;
}
.ourExecutiveBoard {
  position: absolute;
  top: 6816px;
  left: calc(50% - 462.5px);
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  display: inline-block;
  width: 935px;
  height: 503px;
}
.amazonIcon,
.googleIcon {
  position: absolute;
  top: 0;
  left: calc(50% - 413px);
  width: 214px;
  height: 120px;
  object-fit: cover;
}
.amazonIcon {
  top: 36px;
  left: calc(50% - 139px);
  width: 255px;
  height: 77px;
}
.bloombergIcon,
.coinbaseIcon,
.microsoftIcon,
.snapchatIcon {
  position: absolute;
  top: 134px;
  left: calc(50% - 529px);
  width: 306px;
  height: 65px;
  object-fit: cover;
}
.bloombergIcon,
.coinbaseIcon,
.snapchatIcon {
  left: calc(50% + 263px);
  width: 266px;
  height: 92px;
}
.bloombergIcon,
.snapchatIcon {
  top: 130px;
  left: calc(50% - 193px);
  width: 97px;
  height: 96px;
}
.bloombergIcon {
  top: 155px;
  left: calc(50% - 58px);
  width: 296px;
  height: 55px;
}
.linkedinLogoIcon,
.metaLogoIcon,
.stripeLogoIcon {
  position: absolute;
  top: 12px;
  left: calc(50% + 160px);
  width: 294px;
  height: 95px;
  object-fit: cover;
}
.linkedinLogoIcon,
.stripeLogoIcon {
  top: 248px;
  left: calc(50% - 306px);
  width: 292px;
  height: 75px;
}
.stripeLogoIcon {
  top: 230px;
  left: calc(50% + 63px);
  width: 248px;
  height: 118px;
}
.alumniCompanies {
  position: absolute;
  top: 7765px;
  left: calc(50% - 528.5px);
  width: 1058px;
  height: 348px;
}
.rollercoasterphoto1Icon {
  position: absolute;
  top: 1780px;
  right: -92px;
  width: 707px;
  height: 809px;
  object-fit: cover;
}
.ethanSScreenshot1Icon,
.image2Icon,
.paulScreenshot1Icon {
  position: absolute;
  top: 1px;
  left: calc(50% - 211px);
  border-radius: var(--br-11xl);
  width: 188px;
  height: 189px;
  object-fit: cover;
}
.image2Icon,
.paulScreenshot1Icon {
  left: calc(50% - 449px);
  height: 188px;
}
.image2Icon {
  left: calc(50% + 265px);
  width: 189px;
  height: 189px;
}
.image3Icon,
.image4Icon,
.image6Icon,
.jakeScreenshot1Icon,
.screenshot20230630At426 {
  position: absolute;
  top: 216px;
  left: calc(50% - 214px);
  border-radius: var(--br-11xl);
  width: 193px;
  height: 192px;
  object-fit: cover;
}
.image4Icon,
.image6Icon,
.jakeScreenshot1Icon,
.screenshot20230630At426 {
  left: calc(50% + 264px);
  width: 190px;
  height: 189px;
}
.image6Icon,
.jakeScreenshot1Icon,
.screenshot20230630At426 {
  top: 215px;
  left: calc(50% - 454px);
  width: 196px;
  height: 193px;
}
.image6Icon,
.screenshot20230630At426 {
  left: calc(50% + 24px);
  height: 196px;
}
.screenshot20230630At426 {
  top: 0;
  left: calc(50% + 27px);
  width: 188px;
  height: 189px;
}
.cohort11,
.eboardPhotos {
  position: absolute;
  top: 6965px;
  left: calc(50% - 453.5px);
  width: 908px;
  height: 411px;
}
.cohort11 {
  top: 0;
  left: calc(50% - 232px);
  width: 536px;
  height: 414px;
}
.froodphoto1Icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.cohort21,
.cohort3,
.cohort31 {
  position: absolute;
  top: 40px;
  left: calc(50% - 677px);
  width: 529px;
  height: 342px;
}
.cohort3,
.cohort31 {
  left: calc(50% + 244px);
  width: 433px;
  height: 350px;
}
.cohort3 {
  top: 4525px;
  left: calc(50% - 676.5px);
  width: 1354px;
  height: 414px;
}
.logo,
.purpleinf1Icon {
  position: absolute;
  height: 50px;
}
.purpleinf1Icon {
  top: 0;
  left: 0;
  width: 104.62px;
  object-fit: cover;
  cursor: pointer;
}
.logo {
  top: 26px;
  left: 64px;
  width: 100px;
}
.menuLogo {
  /* position: relative; */
  /* top: 26px;
  left: 64px;
  width: 96.65px; */
  height: 49px;
  background-image: url(/menu-bar@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  transition: all .3s ease-in-out;
}
.menuLogo:hover {
  height: 47px;
  width: 100px;
  background-image: url(/silverInfinity.png);
  filter: drop-shadow(2px 4px 1px var(--color-blueviolet));
  transition: all .3s ease-in-out;
  cursor: pointer;
}
.about3,
.cohort4,
.faq4,
.learn4,
.scopeCup2,
.sponsor2 {
  position: absolute;
  float: left;
  width: 7%;
  top: 37px;
  left: 15.26%;
  display: inline-block;
  cursor: pointer;
}
.cohort4,
.faq4,
.learn4,
.scopeCup2,
.sponsor2 {
  width: 6.5%;
  left: 27.74%;
}
.cohort4,
.faq4,
.scopeCup2,
.sponsor2 {
  width: 8.57%;
  left: 39.72%;
}
.faq4,
.scopeCup2,
.sponsor2 {
  width: 3.71%;
  left: 53.77%;
}
.scopeCup2,
.sponsor2 {
  width: 9.69%;
  left: 62.96%;
}
.scopeCup2 {
  width: 11.51%;
  left: 78.13%;
}
.container {
  width: 900px;
  height: 500px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: space-evenly;

  img {
    border-radius: 12px;
  }
}
.scopeLogo {
  /* visibility: hidden; */
}
.content {
  /* visibility: hidden; */
  background-color: #f4f2eb;
}
.middle {
  color: #5b04f6;
  font-weight: 400;
  font-size: 1.3rem;
  letter-spacing: 2px;
  z-index: 99;
	top: 7%;
	left: 50%;
	position: absolute;
	transform: translateX(-50%) translateY(-50%) scale(2, 2);
	transition: 0.5s ease-in-out;
}
/* .mainPage, */
.menuBar {
  background-color: var(--color-linen);
  overflow: hidden;
  visibility: hidden;
  position: fixed;
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  color: var(--color-blueviolet);
  font-family: 'Raleway';
}
.menuBar {
  margin-bottom: 100px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 102px;
  font-size: var(--font-size-13xl);
}
.mainPage {
  position: relative;
  height: 8265px;
  overflow: visible;
  overflow-x: hidden;
  font-size: var(--font-size-31xl);
  background-color: var(--color-linen);
  /* border: 1px solid var(--color-black); */
  /* box-sizing: border-box; */
  width: 100%;
  max-width: 100%;
  text-align: center;
  color: var(--color-blueviolet);
  font-family: 'Raleway';
  background-color: #f4f2eb;
  zoom: 0.67;
}
html{
  scroll-behavior: smooth;
  background-color: #f4f2eb;
}

@media screen and (max-width: 600px) {
  .scopeLogo {
    background-size: 65%;
    margin: auto;
  }
  .scopeLogo:hover {
    background-size: 65%;
    margin:auto;
  }
  .scopegradientorb1Icon {
    right: 0;
    width: 100%;
  }
  .scopegradientorb2Icon {
    left: 0;
    width: 100%;
  }
  .learnBuildCode {
    position: absolute;
    top: 5.5%;
    left: 11%;
    margin: auto;
    font-size: 35px;
    font-weight: 600;
    color: var(--color-white);
    text-align: left;
    display: inline-block;
    width: 90%;
    /* height: 74px; */
  }
  .applicationsForTheContainer {
    position: absolute;
    top: 6.34%;
    left: 11%;
    font-size: 40px;
    font-weight: 500;
    color: var(--color-white);
    text-align: left;
    display: inline-block;
    width: 80%;
    height: 135px;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    /* -webkit-text-stroke: 1px #000; */
  }

  .applicationsForThe {
    width: 100%;
  }

  .applicationAtContainer {
    left: 31%;
    
  }
  p.applicationsForThe {
    max-width: 80%;
    width: 70%;
    margin: auto;
    overflow-y: hidden;
  }
  body.applicationsForThe {
    /* visibility: hidden; */
    left: 0%;
    margin: 0;
    font-size: 22px;
    width: 70%;
  }
  .applyNow {
    margin: auto;
    top: 9%;
    padding-left: 25%;
    padding-right: 25%;
    margin-top: 70px;
  }
  .applyNow:hover {
    margin: auto;
    top: 9%;
    padding-left: 25%;
    padding-right: 25%;
  }
  .linkStack { 
    visibility: hidden;
  }
  .githubButtonIcon {
    visibility: visible;
    left: -24%;
    top: 129.5%;
    color: purple;
  }
  .facebookButtonIcon {
    visibility: visible;
    left: 16%;
    top: 130%;
  }
  .instagramButtonIcon {
    visibility: visible;
    left: 56%;
    top: 129.5%;
  }
  .jakepointingIcon {
    visibility: hidden;
  }
  .paulphotoIcon {
    visibility: hidden;
  }
  .hackathonPhotoIcon {
    visibility: hidden;
  }
  .rollercoasterphoto1Icon {
    visibility: hidden;
  }
  .menuBar { 
    visibility: hidden;
  }
  .faq4 {
    visibility: hidden;
  }
  .cohort4 {
    visibility: hidden;
  }
  .about3 {
    visibility: hidden;
  }
  .learn4 {
    visibility: hidden;
  }
  .sponsor2 {
    left: 35%;
  }
  .scopeCup2 {
    width: 30%;
    left: 65%;
  }
  .buildAndLearn {
    width: 80%;
    margin: auto;
  }
  .learnSymbolIcon {
    position: absolute;
    top: -145%;
    left: 44%;
    margin: auto;
    width: 128px;
    height: 105px;
    object-fit: cover;
  }
  .learn3 {
      position: absolute;
      top: -110%;
      left: 44.5%;
      margin: auto;
      width: 128px;
      height: 105px;
      object-fit: cover;
  }
  .learnACuttingEdge {
    position: absolute;
    top: -95%;
    left: 25%;
    width: 50%;
    height: 105px;
    object-fit: cover;
}
.bondSymbolIcon {
  position: absolute;
  top: -70%;
  left: 46%;
  margin: auto;
  object-fit: cover;
}
.collaborate {
    position: absolute;
    top: -28%;
    left: 42.5%;
    margin: auto;
    width: 128px;
    height: 105px;
    object-fit: cover;
}
.teamUpIdeate {
  position: absolute;
  top: -13%;
  left: 25%;
  width: 50%;
  height: 105px;
  object-fit: cover;
}
.belongSymbolIcon {
  position: absolute;
  top: 20%;
  left: 44%;
  margin: auto;
  object-fit: cover;
}
.belong {
    position: absolute;
    top: 65%;
    left: 44.5%;
    margin: auto;
    width: 128px;
    height: 105px;
    object-fit: cover;
}
.joinATightKnit {
  position: absolute;
  top: 80%;
  left: 25%;
  width: 50%;
  height: 105px;
  object-fit: cover;
}
.atScopeWe {
  top: 3047px;
  margin: auto;
  left: 5%;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  width: 90%;
}
.leftCurriculumColumn {
  visibility: hidden;
}
.curriculum {
  position: absolute;
  top: 3350px;
  left: -80%;
  width: 1038px;
  height: 858px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 97px;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
}
.fall2020Virtual{ 
  margin-left: 15%;
  width: 100%;
}
.froodphoto1Icon {
  visibility: hidden;
}
.eachSemesterWe {
  width: 90%;
  left: 5%;
  top: 4600px;
}
.frequentlyAskedQuestions {
  font-size: 40px;
}
.globewShadow1Icon {
  width: 100%;
}
.theCohort {
  top: 4470px;
  left: calc(50% - 475.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
}
.qWhatMakes {
  visibility: hidden;
}
.weAreAn {
  visibility: hidden;
}
.qHowMany {
  visibility: hidden;
}
.qWhatIs {
  visibility: hidden;
}
.weHaveTwo {
  visibility: hidden;
}
.applicationProcess {
  left: 40%;
}
.qWhatDoes {
  left: 33.5%;
}
.scopeLooksFor {
  width: 40%;
  font-size: 22px;
  left: 30%;
}
.qWhatDoes1 {
  left: 32%;
}
.scopeLooksFor {
  width: 39%;
  font-size: 22px;
  left: 31%;
}
b.applicationsForThe {
  /* visibility: hidden; */
}
.qWhoIs {
  left: 39.5%;
}
.scopeIsTo {
  left: 31%;
  font-size: 22px;
  width: 39%;
}
.qDoI {
  left: 35%;
}
.sinceOurCurriculum {
  left: 31%;
  font-size: 22px;
  width: 39%;
}
.googleIcon {
  visibility: hidden;
}
.microsoftIcon {
  visibility: hidden;
}
.metaLogoIcon {
  visibility: hidden;
}
.coinbaseIcon {
  visibility: hidden;
}
.ourExecutiveBoard {
  width: 100%;
  /* font-size: 20px; */
  left: 0%;
}
.eboardPhotos {
  max-width: 100%;
  top: 7035px;
  left: -4%;
}
.ourNetworkOf {
  width: 100%;
  left: 0%;
}
}



@media screen and (max-width: 900px) {
  .scopeLogo {
    background-size: 65%;
    margin: auto;
  }
  .scopeLogo:hover {
    background-size: 65%;
    margin:auto;
  }
  .scopegradientorb1Icon {
    right: 0;
    width: 100%;
  }
  .scopegradientorb2Icon {
    left: 0;
    width: 100%;
  }
  .learnBuildCode {
    position: absolute;
    top: 5.5%;
    left: 11%;
    margin: auto;
    font-size: 35px;
    font-weight: 600;
    color: var(--color-white);
    text-align: left;
    display: inline-block;
    width: 90%;
    /* height: 74px; */
  }
  .applicationsForTheContainer {
    position: absolute;
    top: 6.34%;
    left: 11%;
    font-size: 20px;
    font-weight: 300;
    color: var(--color-white);
    text-align: left;
    display: inline-block;
    width: 80%;
    height: 135px;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    /* -webkit-text-stroke: 1px #000; */
  }

  .applicationsForThe {
    width: 100%;
  }

  .applicationAtContainer {
    left: 31%;
    
  }
  p.applicationsForThe {
    max-width: 90%;
    width: 90%;
    font-size: 28px;
    margin: 0;
    overflow-y: hidden;
  }
  body.applicationsForThe {
    /* visibility: hidden; */
    left: 20%;
    margin-left: 2%;
    font-size: 22px;
    width: 80%;
  }
  .applyNow {
    margin: auto;
    top: 9%;
    padding-left: 25%;
    padding-right: 25%;
    margin-top: 70px;
  }
  .applyNow:hover {
    margin: auto;
    top: 9%;
    padding-left: 25%;
    padding-right: 25%;
    margin-top: 70px;
  }
  .linkStack { 
    visibility: hidden;
  }
  .githubButtonIcon {
    visibility: visible;
    left: -24%;
    top: 129.5%;
    color: purple;
  }
  .facebookButtonIcon {
    visibility: visible;
    left: 16%;
    top: 130%;
  }
  .instagramButtonIcon {
    visibility: visible;
    left: 56%;
    top: 129.5%;
  }
  .jakepointingIcon {
    visibility: hidden;
  }
  .paulphotoIcon {
    visibility: hidden;
  }
  .hackathonPhotoIcon {
    visibility: hidden;
  }
  .rollercoasterphoto1Icon {
    visibility: hidden;
  }
  .menuBar { 
    visibility: hidden;
  }
  .faq4 {
    visibility: hidden;
  }
  .cohort4 {
    visibility: hidden;
  }
  .about3 {
    visibility: hidden;
  }
  .learn4 {
    visibility: hidden;
  }
  .sponsor2 {
    left: 35%;
  }
  .scopeCup2 {
    width: 30%;
    left: 65%;
  }
  .buildAndLearn {
    width: 65%;
    margin: auto;
  }
  .learnSymbolIcon {
    position: absolute;
    top: -145%;
    left: 44%;
    margin: auto;
    width: 128px;
    height: 105px;
    object-fit: cover;
  }
  .learn3 {
      position: absolute;
      top: -110%;
      left: 44.5%;
      margin: auto;
      width: 128px;
      height: 105px;
      object-fit: cover;
  }
  .learnACuttingEdge {
    position: absolute;
    top: -95%;
    left: 25%;
    width: 50%;
    height: 105px;
    object-fit: cover;
}
.bondSymbolIcon {
  position: absolute;
  top: -70%;
  left: 46%;
  margin: auto;
  object-fit: cover;
}
.collaborate {
    position: absolute;
    top: -28%;
    left: 42.5%;
    margin: auto;
    width: 128px;
    height: 105px;
    object-fit: cover;
}
.teamUpIdeate {
  position: absolute;
  top: -13%;
  left: 25%;
  width: 50%;
  height: 105px;
  object-fit: cover;
}
.belongSymbolIcon {
  position: absolute;
  top: 20%;
  left: 44%;
  margin: auto;
  object-fit: cover;
}
.belong {
    position: absolute;
    top: 65%;
    left: 44.5%;
    margin: auto;
    width: 128px;
    height: 105px;
    object-fit: cover;
}
.joinATightKnit {
  position: absolute;
  top: 80%;
  left: 25%;
  width: 50%;
  height: 105px;
  object-fit: cover;
}
.atScopeWe {
  top: 3047px;
  margin: auto;
  left: 3%;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  width: 95%;
}
.leftCurriculumColumn {
  visibility: hidden;
}
.curriculum {
  position: absolute;
  top: 3350px;
  left: -90%;
  margin: auto;
  width: 1038px;
  height: 858px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 97px;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
}
.fall2020Virtual{ 
  margin-left: 15%;
  width: 100%;
}
.froodphoto1Icon {
  visibility: hidden;
}
.eachSemesterWe {
  width: 90%;
  left: 5%;
  top: 4600px;
}
.frequentlyAskedQuestions {
  font-size: 40px;
}
.globewShadow1Icon {
  width: 100%;
}
.theCohort {
  top: 4470px;
  left: calc(50% - 475.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
}
.qWhatMakes {
  visibility: hidden;
}
.weAreAn {
  visibility: hidden;
}
.qHowMany {
  visibility: hidden;
}
.qWhatIs {
  visibility: hidden;
}
.weHaveTwo {
  visibility: hidden;
}
.applicationProcess {
  left: 40%;
}
.qWhatDoes {
  left: 33.5%;
}
.scopeLooksFor {
  width: 40%;
  font-size: 22px;
  left: 30%;
}
.qWhatDoes1 {
  left: 32%;
}
.scopeLooksFor {
  width: 35%;
  font-size: 22px;
  left: 32%;
}
b.applicationsForThe {
  /* visibility: hidden; */
}
.qWhoIs {
  left: 39.5%;
}
.scopeIsTo {
  left: 32%;
  font-size: 22px;
  width: 34%;
}
.qDoI {
  left: 35%;
}
.sinceOurCurriculum {
  left: 32%;
  font-size: 22px;
  width: 35%;
}
.googleIcon {
  visibility: hidden;
}
.microsoftIcon {
  visibility: hidden;
}
.metaLogoIcon {
  visibility: hidden;
}
.coinbaseIcon {
  visibility: hidden;
}
.ourExecutiveBoard {
  width: 100%;
  /* font-size: 20px; */
  left: 0%;
}
.eboardPhotos {
  max-width: 100%;
  top: 7035px;
  left: -4%;
}
.ourNetworkOf {
  width: 100%;
  left: 0%;
}
.middle {
  font-size: 18px;
}
}

@media screen and (max-width: 400px) {
  .scopeLogo {
    background-size: 60%;
    margin: auto;
  }
  .scopeLogo:hover {
    background-size: 60%;
    margin:auto;
  }
  .learnBuildCode {
    position: absolute;
    top: 5.5%;
    left: 11%;
    margin: auto;
    font-size: 30px;
    font-weight: 600;
    color: var(--color-white);
    text-align: left;
    display: inline-block;
    width: 90%;
    /* height: 74px; */
  }
  .theCountdown {
    font-size: 18px;
  }
  .linkedinLogoIcon {
    left: 25%;
  }
  .middle {
    font-size: 15px;
  }
  .frequentlyAskedQuestions {
    font-size: 35px;
  }
}