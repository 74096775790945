.colorstripe21Icon {
  position: absolute;
  /* width: 49.88%; */
  top: 7%;
  left: -10%;
  overflow: visible;
  height: 90%;
  /* object-fit: cover; */
}
.image12Icon,
.image14Icon,
.image16Icon,
.image8Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 109px;
  height: 110px;
  object-fit: cover;
}
.image12Icon,
.image14Icon,
.image16Icon {
  top: 281px;
}
.image14Icon,
.image16Icon {
  top: 141px;
}
.image14Icon {
  top: 422px;
}
.image13Icon,
.image15Icon,
.image17Icon,
.image9Icon {
  position: absolute;
  top: 0;
  left: 123px;
  width: 110px;
  height: 110px;
  object-fit: cover;
}
.image13Icon,
.image15Icon,
.image17Icon {
  top: 281px;
}
.image15Icon,
.image17Icon {
  top: 141px;
}
.image15Icon {
  top: 422px;
}
.div {
  position: absolute;
  top: 41px;
  right: 0;
  display: inline-block;
  width: 120px;
  height: 28px;
}
.p {
  margin: 0;
}
.blankLine {
  margin: 0;
  font-size: var(--font-size-5xl);
}
.div1,
.div2,
.div3 {
  position: absolute;
  top: 182px;
  right: 0;
  display: inline-block;
  width: 120px;
  height: 28px;
}
.div2,
.div3 {
  top: 322px;
}
.div3 {
  top: 463px;
}
.xxXxContainer,
.xxXxContainer1,
.xxXxContainer2,
.xxXxContainer3 {
  position: absolute;
  top: 41px;
  left: 297px;
  display: inline-block;
  width: 158px;
  height: 28px;
}
.xxXxContainer1,
.xxXxContainer2,
.xxXxContainer3 {
  top: 182px;
}
.xxXxContainer2,
.xxXxContainer3 {
  top: 322px;
}
.xxXxContainer3 {
  top: 463px;
}
.image8Parent {
  position: absolute;
  zoom: 0.85;
  top: 30%;
  left: 10.5%;
  width: 733px;
  height: 532px;
}
.scopecup3d1Icon,
.scopecup3d2Icon {
  position: absolute;
  top: 207px;
  right: 110px;
  width: 467px;
  height: 120px;
  object-fit: cover;
}
.scopecup3d2Icon {
  top: 357px;
  right: 123px;
  width: 284px;
  height: 119px;
}
.menuBarChild {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-linen);
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 1743px;
  height: 103px;
}
.purpleinf1Icon {
  position: absolute;
  top: 26px;
  left: 68.43px;
  width: 103.65px;
  height: 50px;
  object-fit: cover;
  cursor: pointer;
}
.aboutLearnCohort {
  position: absolute;
  top: 38px;
  left: 262.66px;
  color: var(--color-limegreen);
  display: none;
  width: 1710.8px;
  height: 65px;
}
.home,
.login,
.menuBar,
.stats {
  position: absolute;
  display: inline-block;
  height: 65px;
}
.home {
  top: 37px;
  left: 1129px;
  width: 96px;
  cursor: pointer;
}
.login,
.menuBar,
.stats {
  top: 38px;
  left: 1342px;
  width: 102px;
}
.login,
.menuBar {
  top: 37px;
  left: 1561px;
  width: 103px;
}
.menuBar {
  top: 0;
  left: -8px;
  width: 1743px;
  height: 103px;
  display: none;
  color: var(--color-blueviolet);
}
.screenshot20230713At923 {
  position: absolute;
  top: 548px;
  right: 91px;
  width: 563px;
  height: 318px;
  object-fit: cover;
}
.menuBarItem {
  position: absolute;
  top: 26px;
  left: 63px;
  background-color: var(--color-linen);
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 1727px;
  height: 103px;
  display: none;
}
.aboutLearnCohort1 {
  position: absolute;
  top: 12px;
  left: 193px;
  display: none;
  width: 1700px;
  height: 65px;
}
.menuBar1 {
  position: relative;
  height: 38px;
  background-image: url(/menu-bar@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  transition: all .3s ease-in-out;
}
.menuBar1:hover {
  background-image: url(/silverInfinity.png);
  width: 80px;
  filter: drop-shadow(2px 4px 1px var(--color-blueviolet));
  transition: all .3s ease-in-out;
}
.home1 {
  position: relative;
  font-size: var(--font-size-5xl);
  color: var(--color-blueviolet);
  text-align: center;
  display: inline-block;
  width: 78px;
  height: 67px;
  flex-shrink: 0;
}

.menuBarParent {
  position: absolute;
  top: 2%;
  left: 5%;
  align-items: center;
  justify-content: center;
  gap: var(--gap-sm);
  color: var(--color-limegreen);
}
.scopeCup {
  position: relative;
  background-color: var(--color-linen);
  width: 100%;
  height: 1060px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
  font-family: var(--font-inter);
  zoom: 0.8;
}
