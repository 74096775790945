.globewShadow1Icon,
.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2 {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 741.4px;
  object-fit: cover;
}
.globewShadow1Icon {
  top: -45%;
  height: 100%;
  width: 100%;
  left: 2%;
  overflow: visible;
}
.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2 {
  height: 75.83%;
  width: 42.06%;
  top: 0.34%;
  right: 39.13%;
  bottom: 23.83%;
  left: 18.81%;
  max-height: 100%;
}
.rectangleIcon1,
.rectangleIcon2 {
  height: 73.22%;
  width: 16.07%;
  top: 0;
  right: 83.93%;
  bottom: 26.78%;
  left: 0;
}
.rectangleIcon2 {
  height: 98.14%;
  width: 36.14%;
  top: 2.17%;
  right: -0.08%;
  bottom: -0.31%;
  left: 63.95%;
}
.scopeOffWhite3d2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 761px;
  height: 197px;
  overflow: hidden;
}
.welcomeToScope,
.whiteScopeLogo {
  position: absolute;
  top: 329px;
  left: calc(50% - 362px);
  width: 761px;
  height: 210px;
}
.welcomeToScope {
  top: 136px;
  left: calc(50% - 348px);
  font-size: var(--font-size-21xl);
  display: inline-block;
  color: var(--color-linen);
  width: 734px;
  height: 146px;
}
.pastSponsors,
.sponsorshipAmount50050 {
  position: absolute;
  top: 668px;
  left: calc(50% - 369px);
  display: inline-block;
  width: 737px;
  height: 146px;
}
.pastSponsors {
  top: 984px;
  height: 66px;
}
.selectAnAmount {
  margin: 0;
}
.selectAnAmountContainer {
  top: 579px;
  left: calc(50% - 450px);
  color: var(--color-black);
  width: 900px;
  height: 81px;
}
.anayModyusc,
.ifYouOrContainer,
.selectAnAmountContainer {
  position: absolute;
  font-size: var(--font-size-5xl);
  display: inline-block;
}
.ifYouOrContainer {
  top: 1346px;
  left: calc(50% - 450px);
  color: var(--color-black);
  width: 900px;
  height: 93px;
}
.anayModyusc {
  top: 1456px;
  left: calc(50% - 252px);
  width: 542px;
  height: 73px;
}
.sponsorPageChild {
  position: absolute;
  top: 838px;
  left: calc(50% - 165px);
  border-radius: 32px;
  background-color: var(--color-blueviolet);
  width: 329px;
  height: 79px;
}
.sponsorPageItem {
  position: absolute;
  top: 741px;
  left: calc(50% - 76px);
  background-color: #d9d9d9;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 144px;
  height: 57px;
}
.janeStreetLogoIcon {
  position: absolute;
  top: 1070px;
  left: calc(50% - 682px);
  width: 305px;
  height: 115px;
  object-fit: cover;
}
.rippleLogoIcon {
  position: absolute;
  top: 1085px;
  left: calc(50% - 350px);
  width: 305px;
  height: 88px;
  object-fit: cover;
}
.microsoftLogoIcon {
  position: absolute;
  top: 1090px;
  left: calc(50% + 0px);
  width: 346px;
  height: 74px;
  object-fit: cover;
}
.metaLogoIcon {
  position: absolute;
  top: 1067px;
  left: calc(50% + 361px);
  width: 317px;
  height: 103px;
  object-fit: cover;
}
.githubLogoIcon {
  position: absolute;
  top: 1190px;
  left: calc(50% - 522px);
  width: 320px;
  height: 87px;
  object-fit: cover;
}
.qualcommLogoIcon {
  position: absolute;
  top: 1205px;
  left: calc(50% - 156px);
  width: 324px;
  height: 59px;
  object-fit: cover;
}
.strivrLogoIcon {
  position: absolute;
  top: 1205px;
  left: calc(50% + 235px);
  width: 368px;
  height: 59px;
  object-fit: cover;
}
.purplescope,
.scopePurple3d1Icon {
  position: absolute;
  width: 790px;
  height: 205px;
}
.scopePurple3d1Icon {
  top: 0;
  left: 0;
  object-fit: cover;
}
.purplescope {
  top: 322px;
  left: 469px;
  display: none;
}
.sponsorEnteredAmount {
  position: absolute;
  top: 868px;
  left: calc(50% - 218px);
  font-size: 17px;
  font-weight: 600;
  color: var(--color-linen);
  display: inline-block;
  width: 435px;
  height: 28px;
}
.menuBarChild {
  position: absolute;
  top: 26px;
  left: 63px;
  background-color: var(--color-linen);
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 1727px;
  height: 103px;
  display: none;
}
.aboutLearnCohort {
  position: absolute;
  top: 12px;
  left: 193px;
  display: none;
  width: 1700px;
  height: 65px;
}
.home,
.menuBar {
  position: relative;
}
.menuBar {
  position: relative;
  height: 38px;
  background-image: url(/menu-bar@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  transition: all .3s ease-in-out;
}
.menuBar:hover {
  background-image: url(/silverInfinity.png);
  width: 80px;
  filter: drop-shadow(2px 4px 1px var(--color-blueviolet));
  transition: all .3s ease-in-out;
}
.home {
  font-size: var(--font-size-5xl);
  color: var(--color-blueviolet);
  text-align: center;
  display: inline-block;
  width: 78px;
  height: 67px;
  flex-shrink: 0;
}
.menuBarParent {
  position: absolute;
  top: 2%;
  left: 5%;
  align-items: center;
  justify-content: center;
  gap: var(--gap-sm);
  color: var(--color-limegreen);
}
.sponsorPage {
  position: relative;
  background-color: var(--color-linen);
  width: 100%;
  height: 1590px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--color-blueviolet);
  font-family: var(--font-inter);
  zoom: 0.67;
}
